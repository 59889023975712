@import 'shared';

.button, .c-bn, #s-bns {
  transition: 0.3s ease all;
  cursor: pointer;
  font-family: $font-family;

  &:focus,
  &:active {
    outline: none;
  }

  /* ------------------- PRIMARY AND SECONDARY ------------------- */

  &.button-primary,
  &.button-secondary,
  &.c-bn,
  &#c-p-bn,
  &#c-s-bn,
  &#s-all-bn,
  &#s-sv-bn,
  &#s-rall-bn {
    font-weight: bold;
    font-size: toRem(16);
    line-height: 112.5%;
    border: 2px solid color(primary, red);
    border-radius: 22px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 11px 48px;

    @include media-breakpoint-down(md) {
      font-size: toRem(13);
    }

    .icon {
      height: 21px;
      width: 21px;
      margin-bottom: -4px;
      margin-top: -1px;

      @include media-breakpoint-down(md) {
        margin-bottom: -6px;
      }

      &.icon-before {
        margin-left: -32px;
        margin-right: 20px;
      }

      &.icon-after {
        margin-right: -32px;
        margin-left: 20px;
      }
    }
  }

  &.button-primary, &#c-p-bn, &#s-all-bn, &#s-sv-bn {
    background: color(black);
    // background: color(primary,red);
    color: color(white);

    &:hover {
      background-color: lighten(color(primary, red), 10%);
      border-color: lighten(color(primary, red), 10%);
      box-shadow: 0 0 0 1px lighten(color(primary, red), 10%);
    }

    &:focus,
    &:active {
      background-color: darken(color(primary, red), 10%);
      border-color: darken(color(primary, red), 10%);
      box-shadow: 0 0 0 1px darken(color(primary, red), 10%);
    }

    &.white {
      background: color(white);
      color: color(primary, red);

      &:hover {
        background-color: lighten(color(white), 10%);
        border-color: lighten(color(white), 10%);
        box-shadow: 0 0 0 1px lighten(color(white), 10%);
      }

      &:focus,
      &:active {
        background-color: darken(color(white), 10%);
        border-color: darken(color(white), 10%);
        box-shadow: 0 0 0 1px darken(color(white), 10%);
      }
    }

    &.white-purple {
      background: color(white);
      color: color(primary, purple);
      border-color: color(primary, purple);

      &:hover {
        background-color: lighten(color(white), 10%);
        border-color: lighten(color(white), 10%);
        box-shadow: 0 0 0 1px lighten(color(white), 10%);
      }

      &:focus,
      &:active {
        background-color: darken(color(white), 10%);
        border-color: darken(color(white), 10%);
        box-shadow: 0 0 0 1px darken(color(white), 10%);
      }
    }

    &.white-red {
      background: color(white);
      color: color(primary, red);
      border-color: color(primary, red);

      &:hover {
        background-color: lighten(color(white), 10%);
        border-color: lighten(color(white), 10%);
        box-shadow: 0 0 0 1px lighten(color(white), 10%);
      }

      &:focus,
      &:active {
        background-color: darken(color(white), 10%);
        border-color: darken(color(white), 10%);
        box-shadow: 0 0 0 1px darken(color(white), 10%);
      }
    }

    &.white-green {
      background: color(white);
      color: color(primary, green);
      border-color: color(primary, green);

      &:hover {
        background-color: lighten(color(white), 10%);
        border-color: lighten(color(white), 10%);
        box-shadow: 0 0 0 1px lighten(color(white), 10%);
      }

      &:focus,
      &:active {
        background-color: darken(color(white), 10%);
        border-color: darken(color(white), 10%);
        box-shadow: 0 0 0 1px darken(color(white), 10%);
      }
    }
  }

  &.button-secondary, &#c-s-bn, &#s-rall-bn {
    color: color(primary, red);

    &:hover {
      box-shadow: 0 0 0 1px color(primary, red);
    }

    &:focus,
    &:active {
      color: darken(color(primary, red), 10%);
      box-shadow: 0 0 0 1px darken(color(primary, red), 10%);
      border-color: darken(color(primary, red), 10%);
    }

    &.white {
      color: color(white);
      border: 2px solid color(white);

      &:hover {
        box-shadow: 0 0 0 1px color(white);
      }

      &:focus,
      &:active {
        color: darken(color(white), 10%);
        box-shadow: 0 0 0 1px darken(color(white), 10%);
        border-color: darken(color(white), 10%);
      }
    }
  }

  /* ------------------- TEXT BUTTON ------------------- */

  &.button-text {
    font-weight: bold;
    font-size: toRem(12);
    line-height: 150%;
    letter-spacing: 0.01em;
    color: color(primary, red);
    text-transform: uppercase;
    position: relative;

    &:after {
      content: ' ';
      display: block;
      height: 1px;
      width: 100%;
      background-color: color(primary, red);
      position: absolute;
      bottom: 0;
      transform: scaleX(0);
      transition: 0.2s ease all;
    }

    &:hover,
    &:active,
    &:focus {
      &:after {
        transform: scaleX(1);
      }
    }

    .icon {
      height: 15px;
      width: 15px;
      margin-bottom: -3px;
      margin-top: -2 px;

      &.icon-small {
        width: 10px;
        height: 10px;
        margin-bottom: 0;
        margin-top: 1px;
      }

      &.icon-before {
        margin-left: 0;
        margin-right: 5px;
      }

      &.icon-after {
        margin-right: 0;
        margin-left: 5px;
      }
    }
  }

  /* ------------------- SPEC BUTTON ------------------- */

  &.button-spec {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 68px;
    font-weight: bold;
    font-size: toRem(16);
    line-height: 125%;
    color: color(white);
    text-transform: uppercase;
    padding-top: 5px;
    padding-left: 18px;
    padding-right: 18px;
    background: color(black);

    @include media-breakpoint-up(lg) {
      min-width: 190px;
    }

    &.purple {
      // background: color(primary,purple);
      &:hover {
        background: lighten(color(primary, purple), 10%);
      }
      &:active,
      &:focus {
        background: darken(color(primary, purple), 10%);
      }
    }
    &.red {
      // background-color: color(primary,red);
      &:hover {
        background: lighten(color(primary, red), 10%);
      }
      &:active,
      &:focus {
        background: darken(color(primary, red), 10%);
      }
    }
    &.green {
      // background: color(primary,green);
      &:hover {
        background: lighten(color(primary, green), 10%);
      }
      &:active,
      &:focus {
        background: darken(color(primary, green), 10%);
      }
    }
    &.blue-light {
      // background: color(primary,blue-light);
      &:hover {
        background: lighten(color(primary, blue-light), 10%);
      }
      &:active,
      &:focus {
        background: darken(color(primary, blue-light), 10%);
      }
    }

    .icon-arrow-right-button-white {
      width: 10px;
      height: 10px;
      margin-top: -3px;
      margin-left: 12px;
      transition: 0.3s ease all;

      &.opened {
        transform: rotate(90deg);
      }
    }
  }
}

.selectlike-container {
  position: relative;
}

.selectlike-button {
  text-align: left;
  width: 350px;
  max-width: 100%;
  border-bottom: 1px solid color(primary, red);
  text-transform: uppercase;
  color: color(primary, red);
  font-size: toRem(16);
  line-height: 150%;
  letter-spacing: 0.01em;
  padding: 9px 40px 9px 17px;
  position: relative;

  &.white {
    color: color(white);
    border-bottom-color: color(white);
  }

  &.type-b {
    font-weight: 600;
    font-size: toRem(20);
    line-height: 125%;
    text-transform: none;
  }

  &:after {
    content: ' ';
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    @include icon('icons/arrow-down-more-red.webp');
    transition: 0.3s ease all;
  }

  &.active {
    &:after {
      transform: translateY(-50%) scaleY(-1);
    }
  }

  &.white:after {
    @include icon('icons/arrow-down-more-white.webp');
  }
}

.selectlike-content-type-a {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  background: color(white);
  text-align: left;
  transform-origin: 50% 0;
  transform: scaleY(0);
  pointer-events: none;
  transition: 0.4s ease all;
  opacity: 0;

  &.wide {
    width: 200%;
    left: 50%;
    transform: translateX(-50%) scaleY(0);

    @include media-breakpoint-down(md) {
      width: 100%;
      left: 0;
      transform: scaleY(0);
    }

    &.opened {
      transform: translateX(-50%) scaleY(1);

      @include media-breakpoint-down(md) {
        transform: scaleY(1);
      }
    }
  }

  &.xxl {
    width: 300%;
    left: -50%;
    transform: translateX(-50%) scaleY(0);

    .thematics {
      width: unset !important;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      left: 0;
      transform: scaleY(0);
    }

    &.opened {
      transform: translateX(-50%) scaleY(1);

      @include media-breakpoint-down(md) {
        transform: scaleY(1);
      }
    }
  }

  &.opened {
    opacity: 1;
    transform: scaleY(1);
    pointer-events: all;
  }

  .element {
    width: 100%;
    padding: 4px 18px;
    color: color(primary, red);
    font-size: toRem(16);
    line-height: 150%;
    text-transform: uppercase;
    text-align: left;
    &:nth-child(even) {
      background: rgba(0, 0, 0, 0.04);
    }
    &:nth-child(odd) {
      background: rgba(0, 0, 0, 0.02);
    }
  }
}

.clear-filter-button {
  display: flex;
  align-items: center;
  margin-top: 55px;
  text-transform: uppercase;
  padding: 14px 10px 10px;
  border-radius: 22px;

  @include media-breakpoint-up(lg) {
    margin-left: auto;
    font-weight: normal;
    font-size: toRem(12);
    line-height: 20px;
    letter-spacing: 0.03em;
    color: color(white);
  }

  @include media-breakpoint-down(md) {
    justify-content: center;
    min-width: 180px;
    margin: 30px auto 0;
    text-align: center;
    font-weight: 700;
    font-size: toRem(16);
    line-height: 18px;
    letter-spacing: 1px;
  }

  &.red {
    @include media-breakpoint-down(md) {
      border: 2.5px solid color(primary, red);
    }

    @include media-breakpoint-down(md) {
      color: color(primary, red);
    }
  }

  &.green {
    @include media-breakpoint-down(md) {
      border: 2.5px solid color(primary, green);
    }

    @include media-breakpoint-down(md) {
      color: color(primary, green);
    }
  }

  &.purple {
    @include media-breakpoint-down(md) {
      border: 2.5px solid color(primary, purple);
    }

    @include media-breakpoint-down(md) {
      color: color(primary, purple);
    }
  }

  .icon {
    @include media-breakpoint-up(lg) {
      width: 12px;
      height: 12px;
      margin-right: 12px;

      &:not(.icon-close-white) {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      &.icon-close-white {
        display: none;
      }
    }
  }
}
