@use '@trendency/consent';

:root {
    #c-inr {
      display: flex;
      max-width: 68em !important;
      font-family: Montserrat, sans-serif;
  
      #c-bns {
        flex-direction: column;
        align-items: baseline;
        gap: 10px;

        #c-s-bn {
          padding: 11px 19px;
        }
      }
    }

    #s-inr {
      #s-bns .c-bn {
        font-size: 13px !important;
        padding: 11px 19px;
        &#s-sv-bn {
          background-color: #EF4E69;
        }
      }
    }

  --cc-bg: #fffffff5;
  --cc-text: #222222;
  --cc-border-radius: 0.35em;
  --cc-btn-primary-bg: #EF4E69;
  --cc-btn-primary-text: #ffffff;
  --cc-btn-primary-hover-bg: #EF4E69;
  --cc-btn-secondary-bg: #ffffff;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: none;
  --cc-btn-border-radius: 4px;
  --cc-toggle-bg-off: #b2b2b2;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #757575;
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-toggle-knob-readonly-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #ebebeb;
  --cc-cookie-category-block-bg-hover: #dbdbdb;
  --cc-section-border: #565656;
  --cc-cookie-table-border: #565656;
  --cc-overlay-bg: rgba(4, 6, 8, 0.65);
  --cc-webkit-scrollbar-bg: #7d7d7d;
  --cc-webkit-scrollbar-bg-hover: #7d7d7d;
}