@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,800&display=swap");
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 600px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 800px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 600px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 800px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1280px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
app-root {
  display: block;
  max-width: 100%;
  overflow: hidden;
}

section > .wrapper {
  width: 1180px;
  margin: 0 auto;
  max-width: calc(100% - 30px);
}

@media (min-width: 1024px) {
  .page-wrapper {
    margin-top: -68px;
  }
}
.section-head.padding-small {
  padding-top: 3px;
}

/* Focusing with a keyboard */
*:focus-visible,
*:focus-visible + label {
  outline: 8px solid #ffa200 !important;
}

/* Focusing with a mouse, touch, or stylus */
*:focus:not(:focus-visible) {
  outline: none !important;
}

input:focus:hover {
  outline: 0 !important;
}

.section-head {
  position: relative;
  padding-top: 80px;
}
@media (max-width: 1023.98px) {
  .section-head {
    padding-top: 20px;
  }
}
.section-head h2 {
  padding-top: 20px;
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #003375;
  padding-bottom: 10px;
  max-width: calc(100% - 10px);
  margin: 0 auto;
}
.section-head .links {
  font-size: 0.75rem;
  line-height: 150%;
  display: block;
  text-align: center;
  font-style: italic;
  padding-bottom: 8px;
  background: #000;
}
.section-head .links .link {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  color: #ef4e69;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}
.section-head .desc {
  display: block;
  text-align: center;
  font-style: italic;
  padding-bottom: 20px;
  width: 550px;
  max-width: calc(100% - 30px);
  margin: 0 auto;
}
@media (max-width: 1023.98px) {
  .section-head .desc:not(.keep-resp) {
    display: none;
  }
}

.custom-gradient:not(.sticky) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 51, 117, 0) 28.65%, rgba(15, 22, 45, 0.75) 100%);
  z-index: 1;
  pointer-events: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  *,
  *:after,
  *:before {
    transition: none !important;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-size: 1em;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

button {
  border: 0;
  background: transparent;
  border-radius: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

[hidden] {
  display: none !important;
}

select {
  word-wrap: normal;
}

button:focus,
a:focus,
input:focus,
textarea:focus {
  outline: none;
}

input,
textarea {
  border: 0;
  font-family: inherit;
}

img,
svg {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

img {
  border-style: none;
}

svg {
  overflow: hidden;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
  padding: 0;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px "Montserrat", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px "Montserrat", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font-size: 96px;
  font-weight: 300;
  line-height: 96px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font-size: 60px;
  font-weight: 300;
  line-height: 60px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font-size: 48px;
  font-weight: 400;
  line-height: 50px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.0178571429em;
}

.mat-button-toggle {
  font-family: "Montserrat", sans-serif;
}

.mat-calendar {
  font-family: "Montserrat", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-expansion-panel-header {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.0178571429em;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: "Montserrat", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 16px;
}

.mat-step-label-selected {
  font-size: 16px;
  font-weight: 400;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.0125em;
  margin: 0;
}

.mat-tree {
  font-family: "Montserrat", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
}

.mat-mdc-card-title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-headline6-font-size, 20px);
  line-height: var(--mdc-typography-headline6-line-height, 32px);
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: var(--mdc-typography-headline6-text-transform, none);
}

.mat-mdc-card-subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 22px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Montserrat, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(16px * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 16px;
}

.mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}

.mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Montserrat, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Montserrat, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Montserrat, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

.mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Montserrat, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Montserrat, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Montserrat, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.009375em;
}

.mat-mdc-paginator {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-paginator .mat-mdc-select-value {
  font-size: 12px;
}

.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
@media all and (-ms-high-contrast: none) {
  .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Montserrat, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, "Montserrat", sans-serif));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 22px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

body {
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  line-height: 125%;
}

h1 {
  font-size: 3.75rem;
}
@media (max-width: 1279.98px) {
  h1 {
    font-size: 2.8125rem;
  }
}

h2 {
  font-size: 3rem;
}
@media (max-width: 1279.98px) {
  h2 {
    font-size: 2.25rem;
  }
}

h3 {
  font-size: 1.875rem;
}
@media (max-width: 1279.98px) {
  h3 {
    font-size: 1.4375rem;
  }
}

h4 {
  font-size: 1.25rem;
}
@media (max-width: 1279.98px) {
  h4 {
    font-size: 0.9375rem;
  }
}

.highlighted-text {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 125%;
}
@media (max-width: 1279.98px) {
  .highlighted-text {
    font-size: 1.6875rem;
  }
}

.lead-text {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 150%;
}
@media (max-width: 1279.98px) {
  .lead-text {
    font-size: 1.4375rem;
  }
}

p {
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;
}

.title-text-content ul,
.text-content ul {
  margin: 25px 0;
}
.title-text-content ul li,
.text-content ul li {
  padding-left: 25px;
  position: relative;
  margin-bottom: 15px;
}
.title-text-content ul li:before,
.text-content ul li:before {
  content: " ";
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  background: #ef4e69;
}

.lead-text.a,
.lead-text a,
.content-text.a,
.content-text a {
  color: #ef4e69;
  text-transform: uppercase;
  font-weight: bold;
}

*[class*=icon-] {
  display: inline-block;
}
*[class*=icon-].icon-heart-full-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/heart-full-red.svg");
}
*[class*=icon-].icon-heart-empty-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/heart-empty-red.svg");
}
*[class*=icon-].icon-heart-empty-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/heart-empty-blue.svg");
}
*[class*=icon-].icon-heart-empty-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-bucketlist.svg");
}
*[class*=icon-].icon-arrow-down-more-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-down-more-red.webp");
}
*[class*=icon-].icon-arrow-down-green {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-down-green.webp");
}
*[class*=icon-].icon-arrow-down-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-down-more-white.webp");
}
*[class*=icon-].icon-arrow-down-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-down-red.svg");
}
*[class*=icon-].icon-arrow-up-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-up-red2.svg");
}
*[class*=icon-].icon-arrow-right-more-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-right-more-red.webp");
}
*[class*=icon-].icon-pin-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/pin-red.svg");
}
*[class*=icon-].icon-pin-black {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/pin-black.svg");
}
*[class*=icon-].icon-pin-green {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pin-24.svg");
}
*[class*=icon-].icon-pin-blue-light {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/pin16-blue-light.svg");
}
*[class*=icon-].icon-pin-black {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pin-black.svg");
}
*[class*=icon-].icon-pin-purple {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/pin16-purple.svg");
}
*[class*=icon-].icon-arrow-right-button-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-right-button-white.webp");
}
*[class*=icon-].icon-profile-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/profile-blue.svg");
}
*[class*=icon-].icon-profile-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/profile-white.svg");
}
*[class*=icon-].icon-search-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/search-blue.svg");
}
*[class*=icon-].icon-search-black {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/search-black.svg");
}
*[class*=icon-].icon-logo {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/main_Magyarorszag_horizontal_inverse.svg");
}
*[class*=icon-].icon-logo-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/magyarorszag_logo.svg");
}
*[class*=icon-].icon-logo-notext {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/main_Magyarorszag_sign.svg");
}
*[class*=icon-].icon-search-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-search.svg");
}
*[class*=icon-].icon-search {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-search.svg");
}
*[class*=icon-].icon-search-green {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-search-green.svg");
}
*[class*=icon-].icon-search-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-search-red.svg");
}
*[class*=icon-].icon-burger-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/burger-white.svg");
}
*[class*=icon-].icon-close-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/x.svg");
}
*[class*=icon-].icon-close-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/x-red.svg");
}
*[class*=icon-].icon-close-green {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/x-green.svg");
}
*[class*=icon-].icon-close-purple {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/x-purple.svg");
}
*[class*=icon-].icon-double-arrow {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/double-arrow.svg");
}
*[class*=icon-].icon-arrow-left-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-left-white.webp");
}
*[class*=icon-].icon-arrow-right-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-right-white.webp");
}
*[class*=icon-].icon-arrow-right-green {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-right-green.webp");
}
*[class*=icon-].icon-arrow-right-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-right-red.svg");
}
*[class*=icon-].icon-photo {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-photo.svg");
}
*[class*=icon-].icon-list-view {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-list-view.svg");
}
*[class*=icon-].icon-list-view-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-list-view-white.svg");
}
*[class*=icon-].icon-filter {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/filter.svg");
}
*[class*=icon-].icon-filter-current-color {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/filter-current-color.svg");
}
*[class*=icon-].icon-map {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/map-icon.svg");
}
*[class*=icon-].icon-impaired {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/impaired.webp");
}
*[class*=icon-].icon-select-arrow-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/select-arrow-blue.webp");
}
*[class*=icon-].icon-calendar-purple {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/calendar-purple.webp");
}
*[class*=icon-].icon-calendar-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/calendar-red.webp");
}
*[class*=icon-].icon-calendar-green {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/calendar-green.webp");
}
*[class*=icon-].icon-calendar-blue-light {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/calendar-blue-light.webp");
}
*[class*=icon-].icon-calendar-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-calendar-white.svg");
}
*[class*=icon-].icon-flag-en {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/en.svg");
}
*[class*=icon-].icon-flag-hu {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/hu.svg");
}
*[class*=icon-].icon-flag-de {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/de.svg");
}
*[class*=icon-].icon-flag-es {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/es.svg");
}
*[class*=icon-].icon-flag-cn {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/cn.svg");
}
*[class*=icon-].icon-flag-hr {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/hr.svg");
}
*[class*=icon-].icon-flag-cs {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/cs.svg");
}
*[class*=icon-].icon-flag-fr {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/fr.svg");
}
*[class*=icon-].icon-flag-he {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/he.svg");
}
*[class*=icon-].icon-flag-it {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/it.svg");
}
*[class*=icon-].icon-flag-ko {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/ko.svg");
}
*[class*=icon-].icon-flag-pl {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/pl.svg");
}
*[class*=icon-].icon-flag-ro {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/ro.svg");
}
*[class*=icon-].icon-flag-ru {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/ru.svg");
}
*[class*=icon-].icon-flag-sr {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/sr.svg");
}
*[class*=icon-].icon-flag-sk {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/sk.svg");
}
*[class*=icon-].icon-flag-sl {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/sl.svg");
}
*[class*=icon-].icon-flag-sv {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/se.svg");
}
*[class*=icon-].icon-languages {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-languages.svg");
}
*[class*=icon-].icon-languages-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-languages-red.svg");
}
*[class*=icon-].icon-wifi {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-wifi.svg");
}
*[class*=icon-].icon-card {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-card.svg");
}
*[class*=icon-].icon-information {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-information.svg");
}
*[class*=icon-].icon-ccard {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-ccard.webp");
}
*[class*=icon-].icon-deaf {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-deaf.webp");
}
*[class*=icon-].icon-disabled {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-disabled.webp");
}
*[class*=icon-].icon-blind {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-blind.webp");
}
*[class*=icon-].icon-car {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/car-black.svg");
}
*[class*=icon-].icon-by-car {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-by-car.svg");
}
*[class*=icon-].icon-by-car-gray {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-by-car-gray.svg");
}
*[class*=icon-].icon-train {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/train.svg");
}
*[class*=icon-].icon-bicycle {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-bicycle.svg");
}
*[class*=icon-].icon-pedestrian {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pedestrian.svg");
}
*[class*=icon-].icon-pedestrian-gray {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pedestrian-gray.svg");
}
*[class*=icon-].icon-time {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/time.svg");
}
*[class*=icon-].icon-pin {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pin-64.svg");
}
*[class*=icon-].icon-pin-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pin-white.svg");
}
*[class*=icon-].icon-pin-purple {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pin-purple.svg");
}
*[class*=icon-].icon-weather {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-weather.svg");
}
*[class*=icon-].icon-download {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/download.svg");
}
*[class*=icon-].icon-facebook-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-facebook-blue.svg");
}
*[class*=icon-].icon-twitter-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-twitter-blue.svg");
}
*[class*=icon-].icon-instagram-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-insta-blue.svg");
}
*[class*=icon-].icon-facebook-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-facebook-white.svg");
}
*[class*=icon-].icon-twitter-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-twitter-white.svg");
}
*[class*=icon-].icon-instagram-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-insta-white.svg");
}
*[class*=icon-].icon-youtube-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-youtube-white.svg");
}
*[class*=icon-].icon-youtube-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-youtube-blue.svg");
}
*[class*=icon-].icon-facebook-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-facebook-red.svg");
}
*[class*=icon-].icon-twitter-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-twitter-red.svg");
}
*[class*=icon-].icon-instagram-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-insta-red.svg");
}
*[class*=icon-].icon-mail-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-mail-red.webp");
}
*[class*=icon-].icon-spring {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-spring.svg");
}
*[class*=icon-].icon-summer {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-summer.svg");
}
*[class*=icon-].icon-autumn {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-autumn.svg");
}
*[class*=icon-].icon-winter {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-winter.svg");
}
*[class*=icon-].icon-sun {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-sun.svg");
}
*[class*=icon-].icon-spring-bold {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-spring-bold.svg");
}
*[class*=icon-].icon-summer-bold {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-summer-bold.svg");
}
*[class*=icon-].icon-autumn-bold {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-autumn-bold.svg");
}
*[class*=icon-].icon-winter-bold {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-winter-bold.webp");
}
*[class*=icon-].icon-sun-bold {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-sun-bold.svg");
}
*[class*=icon-].icon-plane {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-how-to-get-there.svg");
}
*[class*=icon-].icon-van {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-getting-around.svg");
}
*[class*=icon-].icon-tourinform {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-tourinform.svg");
}
*[class*=icon-].icon-tourinform-full {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-tourinform-full.svg");
}
*[class*=icon-].icon-tourinform-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-tourinform-red.svg");
}
*[class*=icon-].icon-smile {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-accessible-hungary.svg");
}
*[class*=icon-].icon-stats {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-facts-figures.svg");
}
*[class*=icon-].icon-dictionary {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-dictionary.svg");
}
*[class*=icon-].icon-currency {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-currency.svg");
}
*[class*=icon-].icon-mapeu-arrow {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/mapeu_arrow.webp");
}
*[class*=icon-].icon-mapeu {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/eu.svg");
}
*[class*=icon-].icon-map1 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map1.webp");
}
*[class*=icon-].icon-map2 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map2.webp");
}
*[class*=icon-].icon-map3 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map3.webp");
}
*[class*=icon-].icon-map4 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map4.webp");
}
*[class*=icon-].icon-map5 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map5.webp");
}
*[class*=icon-].icon-map6 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map6.webp");
}
*[class*=icon-].icon-map7 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map7.webp");
}
*[class*=icon-].icon-map8 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map8.webp");
}
*[class*=icon-].icon-map9 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map9.webp");
}
*[class*=icon-].icon-map10 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map10.webp");
}
*[class*=icon-].map-icon-0 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-0.svg");
}
*[class*=icon-].map-icon-1 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-1.svg");
}
*[class*=icon-].map-icon-2 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-2.svg");
}
*[class*=icon-].map-icon-3 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-3.svg");
}
*[class*=icon-].map-icon-4 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-4.svg");
}
*[class*=icon-].map-icon-5 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-5.svg");
}
*[class*=icon-].map-icon-6 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-6.svg");
}
*[class*=icon-].map-icon-7 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-7.svg");
}
*[class*=icon-].map-icon-8 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-8.svg");
}
*[class*=icon-].map-icon-9 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-9.svg");
}
*[class*=icon-].map-icon-10 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-10.svg");
}
*[class*=icon-].thematic-icon-0 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-0.svg");
}
*[class*=icon-].thematic-icon-1 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-1.svg");
}
*[class*=icon-].thematic-icon-2 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-2.svg");
}
*[class*=icon-].thematic-icon-3 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-3.svg");
}
*[class*=icon-].thematic-icon-4 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-4.svg");
}
*[class*=icon-].thematic-icon-5 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-5.svg");
}
*[class*=icon-].thematic-icon-6 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-6.svg");
}
*[class*=icon-].thematic-icon-7 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-7.svg");
}
*[class*=icon-].thematic-icon-8 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-8.svg");
}
*[class*=icon-].thematic-icon-9 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-9.svg");
}
*[class*=icon-].thematic-icon-10 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-10.svg");
}
*[class*=icon-].thematic-icon-11 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-11.svg");
}
*[class*=icon-].thematic-icon-12 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-12.svg");
}
*[class*=icon-].thematic-icon-13 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-13.svg");
}
*[class*=icon-].thematic-icon-14 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-14.svg");
}
*[class*=icon-].thematic-icon-15 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-15.svg");
}
*[class*=icon-].thematic-icon-16 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-16.svg");
}
*[class*=icon-].thematic-icon-17 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-17.svg");
}
*[class*=icon-].thematic-icon-18 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-18.svg");
}
*[class*=icon-].thematic-icon-19 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-19.svg");
}
*[class*=icon-].thematic-icon-20 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-20.svg");
}
*[class*=icon-].thematic-icon-21 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-21.svg");
}
*[class*=icon-].thematic-icon-22 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-22.svg");
}
*[class*=icon-].thematic-icon-23 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-23.svg");
}
*[class*=icon-].functional-icon-0 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-0.svg");
}
*[class*=icon-].functional-icon-1 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-1.svg");
}
*[class*=icon-].functional-icon-2 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-2.svg");
}
*[class*=icon-].functional-icon-3 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-3.svg");
}
*[class*=icon-].functional-icon-4 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-4.svg");
}
*[class*=icon-].functional-icon-5 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-5.svg");
}
*[class*=icon-].functional-icon-6 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-6.svg");
}
*[class*=icon-].functional-icon-7 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-7.svg");
}
*[class*=icon-].functional-icon-8 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-8.svg");
}
*[class*=icon-].functional-icon-9 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-9.svg");
}
*[class*=icon-].functional-icon-10 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-10.svg");
}
*[class*=icon-].functional-icon-11 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-11.svg");
}
*[class*=icon-].functional-icon-12 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-12.svg");
}
*[class*=icon-].functional-icon-13 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-13.svg");
}
*[class*=icon-].functional-icon-14 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-14.svg");
}
*[class*=icon-].functional-icon-15 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-15.svg");
}
*[class*=icon-].functional-icon-16 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-16.svg");
}
*[class*=icon-].functional-icon-18 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-18.svg");
}
*[class*=icon-].functional-icon-19 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-19.svg");
}
*[class*=icon-].red-functional-icon-0 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-0.svg");
}
*[class*=icon-].red-functional-icon-1 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-1.svg");
}
*[class*=icon-].red-functional-icon-2 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-2.svg");
}
*[class*=icon-].red-functional-icon-3 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-3.svg");
}
*[class*=icon-].red-functional-icon-4 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-4.svg");
}
*[class*=icon-].red-functional-icon-5 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-5.svg");
}
*[class*=icon-].red-functional-icon-6 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-6.svg");
}
*[class*=icon-].red-functional-icon-7 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-7.svg");
}
*[class*=icon-].red-functional-icon-8 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-8.svg");
}
*[class*=icon-].red-functional-icon-9 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-9.svg");
}
*[class*=icon-].red-functional-icon-10 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-10.svg");
}
*[class*=icon-].red-functional-icon-11 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-11.svg");
}
*[class*=icon-].red-functional-icon-12 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-12.svg");
}
*[class*=icon-].red-functional-icon-13 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-13.svg");
}
*[class*=icon-].red-functional-icon-14 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-14.svg");
}
*[class*=icon-].red-functional-icon-15 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-15.svg");
}
*[class*=icon-].red-functional-icon-16 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-16.svg");
}
*[class*=icon-].red-functional-icon-18 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-18.svg");
}
*[class*=icon-].red-functional-icon-19 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-19.svg");
}
*[class*=icon-].red-functional-vehicle-icon-15 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-red-15.svg");
}
*[class*=icon-].red-functional-vehicle-icon-16 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-red-16.svg");
}
*[class*=icon-].red-functional-vehicle-icon-17 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-red-17.svg");
}
*[class*=icon-].red-functional-vehicle-icon-18 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-red-18.svg");
}
*[class*=icon-].red-functional-vehicle-icon-19 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-red-19.svg");
}
*[class*=icon-].functional-vehicle-icon-15 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-15.svg");
}
*[class*=icon-].functional-vehicle-icon-16 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-16.svg");
}
*[class*=icon-].functional-vehicle-icon-17 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-17.svg");
}
*[class*=icon-].functional-vehicle-icon-18 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-18.svg");
}
*[class*=icon-].functional-vehicle-icon-19 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-19.svg");
}
*[class*=icon-].icon-budapest {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/budapest.svg");
}
*[class*=icon-].icon-mtu {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/mtu.webp");
}
*[class*=icon-].icon-store-google {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/store-google.svg");
}
*[class*=icon-].icon-store-ios {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/store-ios.webp");
}
*[class*=icon-].icon-login-facebook {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/login-facebook.svg");
}
*[class*=icon-].icon-login-google {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/login-google.svg");
}
*[class*=icon-].icon-login-username {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/login-username.svg");
}
*[class*=icon-].icon-login-password {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/login-password.svg");
}
*[class*=icon-].icon-city-notitle-balaton {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/balaton.svg");
}
*[class*=icon-].icon-city-notitle-budapest {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/budapest.svg");
}
*[class*=icon-].icon-city-notitle-debrecen {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/debrecen.svg");
}
*[class*=icon-].icon-city-notitle-eger {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/eger.svg");
}
*[class*=icon-].icon-city-notitle-gyor {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/gyor.svg");
}
*[class*=icon-].icon-city-notitle-gyula {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/gyula.svg");
}
*[class*=icon-].icon-city-notitle-pecs {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/pecs.svg");
}
*[class*=icon-].icon-city-notitle-sopron {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/sopron.svg");
}
*[class*=icon-].icon-city-notitle-szeged {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/szeged.svg");
}
*[class*=icon-].icon-city-notitle-tokaj {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/tokaj.svg");
}
*[class*=icon-].practical-info-icon-0 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-0.svg");
}
*[class*=icon-].practical-info-icon-1 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-1.svg");
}
*[class*=icon-].practical-info-icon-2 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-2.svg");
}
*[class*=icon-].practical-info-icon-3 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-3.svg");
}
*[class*=icon-].practical-info-icon-4 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-4.svg");
}
*[class*=icon-].practical-info-icon-5 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-5.svg");
}
*[class*=icon-].practical-info-icon-6 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-6.svg");
}
*[class*=icon-].practical-info-icon-7 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-7.svg");
}
*[class*=icon-].practical-info-icon-8 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-8.svg");
}
*[class*=icon-].icon-mail-big {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-mail-big.svg");
}
*[class*=icon-].icon-accessibility {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon_accessibility.svg");
}
*[class*=icon-].icon-accessibility-vision {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon_accessibility_vision.svg");
}
*[class*=icon-].icon-accessibility-hearing {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon_accessibility_hearing.svg");
}

.button, .c-bn, #s-bns {
  transition: 0.3s ease all;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  /* ------------------- PRIMARY AND SECONDARY ------------------- */
  /* ------------------- TEXT BUTTON ------------------- */
  /* ------------------- SPEC BUTTON ------------------- */
}
.button:focus, .button:active, .c-bn:focus, .c-bn:active, #s-bns:focus, #s-bns:active {
  outline: none;
}
.button.button-primary, .button.button-secondary, .button.c-bn, .button#c-p-bn, .button#c-s-bn, .button#s-all-bn, .button#s-sv-bn, .button#s-rall-bn, .c-bn.button-primary, .c-bn.button-secondary, .c-bn.c-bn, .c-bn#c-p-bn, .c-bn#c-s-bn, .c-bn#s-all-bn, .c-bn#s-sv-bn, .c-bn#s-rall-bn, #s-bns.button-primary, #s-bns.button-secondary, #s-bns.c-bn, #s-bns#c-p-bn, #s-bns#c-s-bn, #s-bns#s-all-bn, #s-bns#s-sv-bn, #s-bns#s-rall-bn {
  font-weight: bold;
  font-size: 1rem;
  line-height: 112.5%;
  border: 2px solid #ef4e69;
  border-radius: 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 11px 48px;
}
@media (max-width: 1023.98px) {
  .button.button-primary, .button.button-secondary, .button.c-bn, .button#c-p-bn, .button#c-s-bn, .button#s-all-bn, .button#s-sv-bn, .button#s-rall-bn, .c-bn.button-primary, .c-bn.button-secondary, .c-bn.c-bn, .c-bn#c-p-bn, .c-bn#c-s-bn, .c-bn#s-all-bn, .c-bn#s-sv-bn, .c-bn#s-rall-bn, #s-bns.button-primary, #s-bns.button-secondary, #s-bns.c-bn, #s-bns#c-p-bn, #s-bns#c-s-bn, #s-bns#s-all-bn, #s-bns#s-sv-bn, #s-bns#s-rall-bn {
    font-size: 0.8125rem;
  }
}
.button.button-primary .icon, .button.button-secondary .icon, .button.c-bn .icon, .button#c-p-bn .icon, .button#c-s-bn .icon, .button#s-all-bn .icon, .button#s-sv-bn .icon, .button#s-rall-bn .icon, .c-bn.button-primary .icon, .c-bn.button-secondary .icon, .c-bn.c-bn .icon, .c-bn#c-p-bn .icon, .c-bn#c-s-bn .icon, .c-bn#s-all-bn .icon, .c-bn#s-sv-bn .icon, .c-bn#s-rall-bn .icon, #s-bns.button-primary .icon, #s-bns.button-secondary .icon, #s-bns.c-bn .icon, #s-bns#c-p-bn .icon, #s-bns#c-s-bn .icon, #s-bns#s-all-bn .icon, #s-bns#s-sv-bn .icon, #s-bns#s-rall-bn .icon {
  height: 21px;
  width: 21px;
  margin-bottom: -4px;
  margin-top: -1px;
}
@media (max-width: 1023.98px) {
  .button.button-primary .icon, .button.button-secondary .icon, .button.c-bn .icon, .button#c-p-bn .icon, .button#c-s-bn .icon, .button#s-all-bn .icon, .button#s-sv-bn .icon, .button#s-rall-bn .icon, .c-bn.button-primary .icon, .c-bn.button-secondary .icon, .c-bn.c-bn .icon, .c-bn#c-p-bn .icon, .c-bn#c-s-bn .icon, .c-bn#s-all-bn .icon, .c-bn#s-sv-bn .icon, .c-bn#s-rall-bn .icon, #s-bns.button-primary .icon, #s-bns.button-secondary .icon, #s-bns.c-bn .icon, #s-bns#c-p-bn .icon, #s-bns#c-s-bn .icon, #s-bns#s-all-bn .icon, #s-bns#s-sv-bn .icon, #s-bns#s-rall-bn .icon {
    margin-bottom: -6px;
  }
}
.button.button-primary .icon.icon-before, .button.button-secondary .icon.icon-before, .button.c-bn .icon.icon-before, .button#c-p-bn .icon.icon-before, .button#c-s-bn .icon.icon-before, .button#s-all-bn .icon.icon-before, .button#s-sv-bn .icon.icon-before, .button#s-rall-bn .icon.icon-before, .c-bn.button-primary .icon.icon-before, .c-bn.button-secondary .icon.icon-before, .c-bn.c-bn .icon.icon-before, .c-bn#c-p-bn .icon.icon-before, .c-bn#c-s-bn .icon.icon-before, .c-bn#s-all-bn .icon.icon-before, .c-bn#s-sv-bn .icon.icon-before, .c-bn#s-rall-bn .icon.icon-before, #s-bns.button-primary .icon.icon-before, #s-bns.button-secondary .icon.icon-before, #s-bns.c-bn .icon.icon-before, #s-bns#c-p-bn .icon.icon-before, #s-bns#c-s-bn .icon.icon-before, #s-bns#s-all-bn .icon.icon-before, #s-bns#s-sv-bn .icon.icon-before, #s-bns#s-rall-bn .icon.icon-before {
  margin-left: -32px;
  margin-right: 20px;
}
.button.button-primary .icon.icon-after, .button.button-secondary .icon.icon-after, .button.c-bn .icon.icon-after, .button#c-p-bn .icon.icon-after, .button#c-s-bn .icon.icon-after, .button#s-all-bn .icon.icon-after, .button#s-sv-bn .icon.icon-after, .button#s-rall-bn .icon.icon-after, .c-bn.button-primary .icon.icon-after, .c-bn.button-secondary .icon.icon-after, .c-bn.c-bn .icon.icon-after, .c-bn#c-p-bn .icon.icon-after, .c-bn#c-s-bn .icon.icon-after, .c-bn#s-all-bn .icon.icon-after, .c-bn#s-sv-bn .icon.icon-after, .c-bn#s-rall-bn .icon.icon-after, #s-bns.button-primary .icon.icon-after, #s-bns.button-secondary .icon.icon-after, #s-bns.c-bn .icon.icon-after, #s-bns#c-p-bn .icon.icon-after, #s-bns#c-s-bn .icon.icon-after, #s-bns#s-all-bn .icon.icon-after, #s-bns#s-sv-bn .icon.icon-after, #s-bns#s-rall-bn .icon.icon-after {
  margin-right: -32px;
  margin-left: 20px;
}
.button.button-primary, .button#c-p-bn, .button#s-all-bn, .button#s-sv-bn, .c-bn.button-primary, .c-bn#c-p-bn, .c-bn#s-all-bn, .c-bn#s-sv-bn, #s-bns.button-primary, #s-bns#c-p-bn, #s-bns#s-all-bn, #s-bns#s-sv-bn {
  background: #000;
  color: #fff;
}
.button.button-primary:hover, .button#c-p-bn:hover, .button#s-all-bn:hover, .button#s-sv-bn:hover, .c-bn.button-primary:hover, .c-bn#c-p-bn:hover, .c-bn#s-all-bn:hover, .c-bn#s-sv-bn:hover, #s-bns.button-primary:hover, #s-bns#c-p-bn:hover, #s-bns#s-all-bn:hover, #s-bns#s-sv-bn:hover {
  background-color: #f37d91;
  border-color: #f37d91;
  box-shadow: 0 0 0 1px #f37d91;
}
.button.button-primary:focus, .button.button-primary:active, .button#c-p-bn:focus, .button#c-p-bn:active, .button#s-all-bn:focus, .button#s-all-bn:active, .button#s-sv-bn:focus, .button#s-sv-bn:active, .c-bn.button-primary:focus, .c-bn.button-primary:active, .c-bn#c-p-bn:focus, .c-bn#c-p-bn:active, .c-bn#s-all-bn:focus, .c-bn#s-all-bn:active, .c-bn#s-sv-bn:focus, .c-bn#s-sv-bn:active, #s-bns.button-primary:focus, #s-bns.button-primary:active, #s-bns#c-p-bn:focus, #s-bns#c-p-bn:active, #s-bns#s-all-bn:focus, #s-bns#s-all-bn:active, #s-bns#s-sv-bn:focus, #s-bns#s-sv-bn:active {
  background-color: #eb1f41;
  border-color: #eb1f41;
  box-shadow: 0 0 0 1px #eb1f41;
}
.button.button-primary.white, .button#c-p-bn.white, .button#s-all-bn.white, .button#s-sv-bn.white, .c-bn.button-primary.white, .c-bn#c-p-bn.white, .c-bn#s-all-bn.white, .c-bn#s-sv-bn.white, #s-bns.button-primary.white, #s-bns#c-p-bn.white, #s-bns#s-all-bn.white, #s-bns#s-sv-bn.white {
  background: #fff;
  color: #ef4e69;
}
.button.button-primary.white:hover, .button#c-p-bn.white:hover, .button#s-all-bn.white:hover, .button#s-sv-bn.white:hover, .c-bn.button-primary.white:hover, .c-bn#c-p-bn.white:hover, .c-bn#s-all-bn.white:hover, .c-bn#s-sv-bn.white:hover, #s-bns.button-primary.white:hover, #s-bns#c-p-bn.white:hover, #s-bns#s-all-bn.white:hover, #s-bns#s-sv-bn.white:hover {
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 1px white;
}
.button.button-primary.white:focus, .button.button-primary.white:active, .button#c-p-bn.white:focus, .button#c-p-bn.white:active, .button#s-all-bn.white:focus, .button#s-all-bn.white:active, .button#s-sv-bn.white:focus, .button#s-sv-bn.white:active, .c-bn.button-primary.white:focus, .c-bn.button-primary.white:active, .c-bn#c-p-bn.white:focus, .c-bn#c-p-bn.white:active, .c-bn#s-all-bn.white:focus, .c-bn#s-all-bn.white:active, .c-bn#s-sv-bn.white:focus, .c-bn#s-sv-bn.white:active, #s-bns.button-primary.white:focus, #s-bns.button-primary.white:active, #s-bns#c-p-bn.white:focus, #s-bns#c-p-bn.white:active, #s-bns#s-all-bn.white:focus, #s-bns#s-all-bn.white:active, #s-bns#s-sv-bn.white:focus, #s-bns#s-sv-bn.white:active {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 1px #e6e6e6;
}
.button.button-primary.white-purple, .button#c-p-bn.white-purple, .button#s-all-bn.white-purple, .button#s-sv-bn.white-purple, .c-bn.button-primary.white-purple, .c-bn#c-p-bn.white-purple, .c-bn#s-all-bn.white-purple, .c-bn#s-sv-bn.white-purple, #s-bns.button-primary.white-purple, #s-bns#c-p-bn.white-purple, #s-bns#s-all-bn.white-purple, #s-bns#s-sv-bn.white-purple {
  background: #fff;
  color: #7c51a1;
  border-color: #7c51a1;
}
.button.button-primary.white-purple:hover, .button#c-p-bn.white-purple:hover, .button#s-all-bn.white-purple:hover, .button#s-sv-bn.white-purple:hover, .c-bn.button-primary.white-purple:hover, .c-bn#c-p-bn.white-purple:hover, .c-bn#s-all-bn.white-purple:hover, .c-bn#s-sv-bn.white-purple:hover, #s-bns.button-primary.white-purple:hover, #s-bns#c-p-bn.white-purple:hover, #s-bns#s-all-bn.white-purple:hover, #s-bns#s-sv-bn.white-purple:hover {
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 1px white;
}
.button.button-primary.white-purple:focus, .button.button-primary.white-purple:active, .button#c-p-bn.white-purple:focus, .button#c-p-bn.white-purple:active, .button#s-all-bn.white-purple:focus, .button#s-all-bn.white-purple:active, .button#s-sv-bn.white-purple:focus, .button#s-sv-bn.white-purple:active, .c-bn.button-primary.white-purple:focus, .c-bn.button-primary.white-purple:active, .c-bn#c-p-bn.white-purple:focus, .c-bn#c-p-bn.white-purple:active, .c-bn#s-all-bn.white-purple:focus, .c-bn#s-all-bn.white-purple:active, .c-bn#s-sv-bn.white-purple:focus, .c-bn#s-sv-bn.white-purple:active, #s-bns.button-primary.white-purple:focus, #s-bns.button-primary.white-purple:active, #s-bns#c-p-bn.white-purple:focus, #s-bns#c-p-bn.white-purple:active, #s-bns#s-all-bn.white-purple:focus, #s-bns#s-all-bn.white-purple:active, #s-bns#s-sv-bn.white-purple:focus, #s-bns#s-sv-bn.white-purple:active {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 1px #e6e6e6;
}
.button.button-primary.white-red, .button#c-p-bn.white-red, .button#s-all-bn.white-red, .button#s-sv-bn.white-red, .c-bn.button-primary.white-red, .c-bn#c-p-bn.white-red, .c-bn#s-all-bn.white-red, .c-bn#s-sv-bn.white-red, #s-bns.button-primary.white-red, #s-bns#c-p-bn.white-red, #s-bns#s-all-bn.white-red, #s-bns#s-sv-bn.white-red {
  background: #fff;
  color: #ef4e69;
  border-color: #ef4e69;
}
.button.button-primary.white-red:hover, .button#c-p-bn.white-red:hover, .button#s-all-bn.white-red:hover, .button#s-sv-bn.white-red:hover, .c-bn.button-primary.white-red:hover, .c-bn#c-p-bn.white-red:hover, .c-bn#s-all-bn.white-red:hover, .c-bn#s-sv-bn.white-red:hover, #s-bns.button-primary.white-red:hover, #s-bns#c-p-bn.white-red:hover, #s-bns#s-all-bn.white-red:hover, #s-bns#s-sv-bn.white-red:hover {
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 1px white;
}
.button.button-primary.white-red:focus, .button.button-primary.white-red:active, .button#c-p-bn.white-red:focus, .button#c-p-bn.white-red:active, .button#s-all-bn.white-red:focus, .button#s-all-bn.white-red:active, .button#s-sv-bn.white-red:focus, .button#s-sv-bn.white-red:active, .c-bn.button-primary.white-red:focus, .c-bn.button-primary.white-red:active, .c-bn#c-p-bn.white-red:focus, .c-bn#c-p-bn.white-red:active, .c-bn#s-all-bn.white-red:focus, .c-bn#s-all-bn.white-red:active, .c-bn#s-sv-bn.white-red:focus, .c-bn#s-sv-bn.white-red:active, #s-bns.button-primary.white-red:focus, #s-bns.button-primary.white-red:active, #s-bns#c-p-bn.white-red:focus, #s-bns#c-p-bn.white-red:active, #s-bns#s-all-bn.white-red:focus, #s-bns#s-all-bn.white-red:active, #s-bns#s-sv-bn.white-red:focus, #s-bns#s-sv-bn.white-red:active {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 1px #e6e6e6;
}
.button.button-primary.white-green, .button#c-p-bn.white-green, .button#s-all-bn.white-green, .button#s-sv-bn.white-green, .c-bn.button-primary.white-green, .c-bn#c-p-bn.white-green, .c-bn#s-all-bn.white-green, .c-bn#s-sv-bn.white-green, #s-bns.button-primary.white-green, #s-bns#c-p-bn.white-green, #s-bns#s-all-bn.white-green, #s-bns#s-sv-bn.white-green {
  background: #fff;
  color: #38ba9b;
  border-color: #38ba9b;
}
.button.button-primary.white-green:hover, .button#c-p-bn.white-green:hover, .button#s-all-bn.white-green:hover, .button#s-sv-bn.white-green:hover, .c-bn.button-primary.white-green:hover, .c-bn#c-p-bn.white-green:hover, .c-bn#s-all-bn.white-green:hover, .c-bn#s-sv-bn.white-green:hover, #s-bns.button-primary.white-green:hover, #s-bns#c-p-bn.white-green:hover, #s-bns#s-all-bn.white-green:hover, #s-bns#s-sv-bn.white-green:hover {
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 1px white;
}
.button.button-primary.white-green:focus, .button.button-primary.white-green:active, .button#c-p-bn.white-green:focus, .button#c-p-bn.white-green:active, .button#s-all-bn.white-green:focus, .button#s-all-bn.white-green:active, .button#s-sv-bn.white-green:focus, .button#s-sv-bn.white-green:active, .c-bn.button-primary.white-green:focus, .c-bn.button-primary.white-green:active, .c-bn#c-p-bn.white-green:focus, .c-bn#c-p-bn.white-green:active, .c-bn#s-all-bn.white-green:focus, .c-bn#s-all-bn.white-green:active, .c-bn#s-sv-bn.white-green:focus, .c-bn#s-sv-bn.white-green:active, #s-bns.button-primary.white-green:focus, #s-bns.button-primary.white-green:active, #s-bns#c-p-bn.white-green:focus, #s-bns#c-p-bn.white-green:active, #s-bns#s-all-bn.white-green:focus, #s-bns#s-all-bn.white-green:active, #s-bns#s-sv-bn.white-green:focus, #s-bns#s-sv-bn.white-green:active {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 1px #e6e6e6;
}
.button.button-secondary, .button#c-s-bn, .button#s-rall-bn, .c-bn.button-secondary, .c-bn#c-s-bn, .c-bn#s-rall-bn, #s-bns.button-secondary, #s-bns#c-s-bn, #s-bns#s-rall-bn {
  color: #ef4e69;
}
.button.button-secondary:hover, .button#c-s-bn:hover, .button#s-rall-bn:hover, .c-bn.button-secondary:hover, .c-bn#c-s-bn:hover, .c-bn#s-rall-bn:hover, #s-bns.button-secondary:hover, #s-bns#c-s-bn:hover, #s-bns#s-rall-bn:hover {
  box-shadow: 0 0 0 1px #ef4e69;
}
.button.button-secondary:focus, .button.button-secondary:active, .button#c-s-bn:focus, .button#c-s-bn:active, .button#s-rall-bn:focus, .button#s-rall-bn:active, .c-bn.button-secondary:focus, .c-bn.button-secondary:active, .c-bn#c-s-bn:focus, .c-bn#c-s-bn:active, .c-bn#s-rall-bn:focus, .c-bn#s-rall-bn:active, #s-bns.button-secondary:focus, #s-bns.button-secondary:active, #s-bns#c-s-bn:focus, #s-bns#c-s-bn:active, #s-bns#s-rall-bn:focus, #s-bns#s-rall-bn:active {
  color: #eb1f41;
  box-shadow: 0 0 0 1px #eb1f41;
  border-color: #eb1f41;
}
.button.button-secondary.white, .button#c-s-bn.white, .button#s-rall-bn.white, .c-bn.button-secondary.white, .c-bn#c-s-bn.white, .c-bn#s-rall-bn.white, #s-bns.button-secondary.white, #s-bns#c-s-bn.white, #s-bns#s-rall-bn.white {
  color: #fff;
  border: 2px solid #fff;
}
.button.button-secondary.white:hover, .button#c-s-bn.white:hover, .button#s-rall-bn.white:hover, .c-bn.button-secondary.white:hover, .c-bn#c-s-bn.white:hover, .c-bn#s-rall-bn.white:hover, #s-bns.button-secondary.white:hover, #s-bns#c-s-bn.white:hover, #s-bns#s-rall-bn.white:hover {
  box-shadow: 0 0 0 1px #fff;
}
.button.button-secondary.white:focus, .button.button-secondary.white:active, .button#c-s-bn.white:focus, .button#c-s-bn.white:active, .button#s-rall-bn.white:focus, .button#s-rall-bn.white:active, .c-bn.button-secondary.white:focus, .c-bn.button-secondary.white:active, .c-bn#c-s-bn.white:focus, .c-bn#c-s-bn.white:active, .c-bn#s-rall-bn.white:focus, .c-bn#s-rall-bn.white:active, #s-bns.button-secondary.white:focus, #s-bns.button-secondary.white:active, #s-bns#c-s-bn.white:focus, #s-bns#c-s-bn.white:active, #s-bns#s-rall-bn.white:focus, #s-bns#s-rall-bn.white:active {
  color: #e6e6e6;
  box-shadow: 0 0 0 1px #e6e6e6;
  border-color: #e6e6e6;
}
.button.button-text, .c-bn.button-text, #s-bns.button-text {
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #ef4e69;
  text-transform: uppercase;
  position: relative;
}
.button.button-text:after, .c-bn.button-text:after, #s-bns.button-text:after {
  content: " ";
  display: block;
  height: 1px;
  width: 100%;
  background-color: #ef4e69;
  position: absolute;
  bottom: 0;
  transform: scaleX(0);
  transition: 0.2s ease all;
}
.button.button-text:hover:after, .button.button-text:active:after, .button.button-text:focus:after, .c-bn.button-text:hover:after, .c-bn.button-text:active:after, .c-bn.button-text:focus:after, #s-bns.button-text:hover:after, #s-bns.button-text:active:after, #s-bns.button-text:focus:after {
  transform: scaleX(1);
}
.button.button-text .icon, .c-bn.button-text .icon, #s-bns.button-text .icon {
  height: 15px;
  width: 15px;
  margin-bottom: -3px;
  margin-top: -2 px;
}
.button.button-text .icon.icon-small, .c-bn.button-text .icon.icon-small, #s-bns.button-text .icon.icon-small {
  width: 10px;
  height: 10px;
  margin-bottom: 0;
  margin-top: 1px;
}
.button.button-text .icon.icon-before, .c-bn.button-text .icon.icon-before, #s-bns.button-text .icon.icon-before {
  margin-left: 0;
  margin-right: 5px;
}
.button.button-text .icon.icon-after, .c-bn.button-text .icon.icon-after, #s-bns.button-text .icon.icon-after {
  margin-right: 0;
  margin-left: 5px;
}
.button.button-spec, .c-bn.button-spec, #s-bns.button-spec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  font-weight: bold;
  font-size: 1rem;
  line-height: 125%;
  color: #fff;
  text-transform: uppercase;
  padding-top: 5px;
  padding-left: 18px;
  padding-right: 18px;
  background: #000;
}
@media (min-width: 1024px) {
  .button.button-spec, .c-bn.button-spec, #s-bns.button-spec {
    min-width: 190px;
  }
}
.button.button-spec.purple:hover, .c-bn.button-spec.purple:hover, #s-bns.button-spec.purple:hover {
  background: #956fb6;
}
.button.button-spec.purple:active, .button.button-spec.purple:focus, .c-bn.button-spec.purple:active, .c-bn.button-spec.purple:focus, #s-bns.button-spec.purple:active, #s-bns.button-spec.purple:focus {
  background: #62407f;
}
.button.button-spec.red:hover, .c-bn.button-spec.red:hover, #s-bns.button-spec.red:hover {
  background: #f37d91;
}
.button.button-spec.red:active, .button.button-spec.red:focus, .c-bn.button-spec.red:active, .c-bn.button-spec.red:focus, #s-bns.button-spec.red:active, #s-bns.button-spec.red:focus {
  background: #eb1f41;
}
.button.button-spec.green:hover, .c-bn.button-spec.green:hover, #s-bns.button-spec.green:hover {
  background: #58cdb1;
}
.button.button-spec.green:active, .button.button-spec.green:focus, .c-bn.button-spec.green:active, .c-bn.button-spec.green:focus, #s-bns.button-spec.green:active, #s-bns.button-spec.green:focus {
  background: #2c937a;
}
.button.button-spec.blue-light:hover, .c-bn.button-spec.blue-light:hover, #s-bns.button-spec.blue-light:hover {
  background: #98bde2;
}
.button.button-spec.blue-light:active, .button.button-spec.blue-light:focus, .c-bn.button-spec.blue-light:active, .c-bn.button-spec.blue-light:focus, #s-bns.button-spec.blue-light:active, #s-bns.button-spec.blue-light:focus {
  background: #4889cc;
}
.button.button-spec .icon-arrow-right-button-white, .c-bn.button-spec .icon-arrow-right-button-white, #s-bns.button-spec .icon-arrow-right-button-white {
  width: 10px;
  height: 10px;
  margin-top: -3px;
  margin-left: 12px;
  transition: 0.3s ease all;
}
.button.button-spec .icon-arrow-right-button-white.opened, .c-bn.button-spec .icon-arrow-right-button-white.opened, #s-bns.button-spec .icon-arrow-right-button-white.opened {
  transform: rotate(90deg);
}

.selectlike-container {
  position: relative;
}

.selectlike-button {
  text-align: left;
  width: 350px;
  max-width: 100%;
  border-bottom: 1px solid #ef4e69;
  text-transform: uppercase;
  color: #ef4e69;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: 0.01em;
  padding: 9px 40px 9px 17px;
  position: relative;
}
.selectlike-button.white {
  color: #fff;
  border-bottom-color: #fff;
}
.selectlike-button.type-b {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 125%;
  text-transform: none;
}
.selectlike-button:after {
  content: " ";
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-down-more-red.webp");
  transition: 0.3s ease all;
}
.selectlike-button.active:after {
  transform: translateY(-50%) scaleY(-1);
}
.selectlike-button.white:after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-down-more-white.webp");
}

.selectlike-content-type-a {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  background: #fff;
  text-align: left;
  transform-origin: 50% 0;
  transform: scaleY(0);
  pointer-events: none;
  transition: 0.4s ease all;
  opacity: 0;
}
.selectlike-content-type-a.wide {
  width: 200%;
  left: 50%;
  transform: translateX(-50%) scaleY(0);
}
@media (max-width: 1023.98px) {
  .selectlike-content-type-a.wide {
    width: 100%;
    left: 0;
    transform: scaleY(0);
  }
}
.selectlike-content-type-a.wide.opened {
  transform: translateX(-50%) scaleY(1);
}
@media (max-width: 1023.98px) {
  .selectlike-content-type-a.wide.opened {
    transform: scaleY(1);
  }
}
.selectlike-content-type-a.xxl {
  width: 300%;
  left: -50%;
  transform: translateX(-50%) scaleY(0);
}
.selectlike-content-type-a.xxl .thematics {
  width: unset !important;
}
@media (max-width: 1023.98px) {
  .selectlike-content-type-a.xxl {
    width: 100%;
    left: 0;
    transform: scaleY(0);
  }
}
.selectlike-content-type-a.xxl.opened {
  transform: translateX(-50%) scaleY(1);
}
@media (max-width: 1023.98px) {
  .selectlike-content-type-a.xxl.opened {
    transform: scaleY(1);
  }
}
.selectlike-content-type-a.opened {
  opacity: 1;
  transform: scaleY(1);
  pointer-events: all;
}
.selectlike-content-type-a .element {
  width: 100%;
  padding: 4px 18px;
  color: #ef4e69;
  font-size: 1rem;
  line-height: 150%;
  text-transform: uppercase;
  text-align: left;
}
.selectlike-content-type-a .element:nth-child(even) {
  background: rgba(0, 0, 0, 0.04);
}
.selectlike-content-type-a .element:nth-child(odd) {
  background: rgba(0, 0, 0, 0.02);
}

.clear-filter-button {
  display: flex;
  align-items: center;
  margin-top: 55px;
  text-transform: uppercase;
  padding: 14px 10px 10px;
  border-radius: 22px;
}
@media (min-width: 1024px) {
  .clear-filter-button {
    margin-left: auto;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #fff;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button {
    justify-content: center;
    min-width: 180px;
    margin: 30px auto 0;
    text-align: center;
    font-weight: 700;
    font-size: 1rem;
    line-height: 18px;
    letter-spacing: 1px;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button.red {
    border: 2.5px solid #ef4e69;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button.red {
    color: #ef4e69;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button.green {
    border: 2.5px solid #38ba9b;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button.green {
    color: #38ba9b;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button.purple {
    border: 2.5px solid #7c51a1;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button.purple {
    color: #7c51a1;
  }
}
@media (min-width: 1024px) {
  .clear-filter-button .icon {
    width: 12px;
    height: 12px;
    margin-right: 12px;
  }
  .clear-filter-button .icon:not(.icon-close-white) {
    display: none;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  .clear-filter-button .icon.icon-close-white {
    display: none;
  }
}

ul li .content {
  transition: transform 0.5s linear;
}

ul li:not(.slick-current) .content {
  transform: scale(0.9);
}

ul li .content {
  transition: transform 0.3s linear;
  transition-delay: 0.5s;
}

ul li:not(.slick-current) .content {
  transform: scale(0.9);
  transition-delay: 0s;
}

.slick-slide {
  outline: none;
}

.slick-prev,
.slick-next {
  width: 42px;
  height: 42px;
  z-index: 105;
  background-color: red;
}
.slick-prev:before,
.slick-next:before {
  content: "";
}
@media (max-width: 799.98px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.slick-prev {
  left: 20px;
}

.slick-next {
  right: 20px;
}

.slick-dotted.slick-slider {
  margin: 0;
}

.slick-dots {
  bottom: 25px;
  transform: translateX(-50%);
  width: auto;
  left: 50%;
}
@media (max-width: 799.98px) {
  .slick-dots {
    display: none !important;
    width: 100%;
  }
}
.slick-dots li {
  width: auto;
  height: auto;
  margin: 0 7.5px;
  line-height: 0;
}
.slick-dots li.slick-active button {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/cardot-active.svg");
}
@media (max-width: 799.98px) {
  .slick-dots li {
    margin: 0 2.5px;
  }
}
.slick-dots li button {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/cardot.svg");
  width: 28px;
  height: 28px;
  padding: 0;
}
.slick-dots li button:before {
  display: none;
}
@media (max-width: 799.98px) {
  .slick-dots li button {
    width: 20px;
    height: 20px;
  }
}

.custom-input input[type=text],
.custom-input input[type=email],
.custom-input input[type=password] {
  width: 100%;
  background: #efeff0;
  border-radius: 30px;
  height: 44px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #2d2b2c;
}
.custom-input input[type=text]::placeholder,
.custom-input input[type=email]::placeholder,
.custom-input input[type=password]::placeholder {
  color: #c4c4c4;
}

/* Basic styles */
.custom-checkbox {
  /* Checkbox */
  /* Radio */
  /* :checked */
  /* Transition */
}
.custom-checkbox input[type=checkbox],
.custom-checkbox input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.custom-checkbox label {
  position: relative;
  display: inline-block;
  padding: 0 0 0 50px;
  height: 1.5em;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
}
.custom-checkbox label::before,
.custom-checkbox label::after {
  position: absolute;
  top: calc(50% - 9px);
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
}
.custom-checkbox label::before {
  content: " ";
  border: 1px solid #fff;
}
.custom-checkbox input[type=checkbox] + label::after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/check.svg");
  background-size: 60%;
  content: " ";
}
.custom-checkbox input[type=radio] + label::before {
  border-radius: 50%;
}
.custom-checkbox input[type=radio] + label::after {
  content: " ";
  top: calc(50% - 9px);
  left: 0;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
}
.custom-checkbox input[type=checkbox]:checked + label::before,
.custom-checkbox input[type=radio]:checked + label::before {
  border-color: #fff;
}
.custom-checkbox input[type=checkbox] + label::after,
.custom-checkbox input[type=radio] + label::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.custom-checkbox input[type=checkbox]:checked + label::after,
.custom-checkbox input[type=radio]:checked + label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.custom-checkbox label::before,
.custom-checkbox label::after {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
.custom-checkbox.black label {
  color: #000;
}
.custom-checkbox.black label::before {
  border: 1px solid #000;
}
.custom-checkbox.black input[type=radio] + label::after {
  background: #000;
  border: 1px solid #000;
}
.custom-checkbox.black input[type=checkbox] + label::after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/check-black.svg");
  background-size: 60%;
}
.custom-checkbox.black input[type=checkbox]:checked + label::before,
.custom-checkbox.black input[type=radio]:checked + label::before {
  border-color: #000;
}

.custom-checkbox-c {
  /* Checkbox */
  /* Radio */
  /* :checked */
  /* Transition */
}
.custom-checkbox-c input[type=checkbox],
.custom-checkbox-c input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.custom-checkbox-c label {
  position: relative;
  display: inline-block;
  padding: 0 0 0 50px;
  height: 1.5em;
  line-height: 1.5;
  cursor: pointer;
  color: #2d2b2c;
}
.custom-checkbox-c label::before,
.custom-checkbox-c label::after {
  position: absolute;
  top: calc(50% - 9px);
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
}
.custom-checkbox-c label::before {
  content: " ";
  border: 1px solid #2d2b2c;
}
.custom-checkbox-c input[type=checkbox] + label::after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/check-black.svg");
  background-size: 60%;
  content: " ";
}
.custom-checkbox-c input[type=radio] + label::before {
  border-radius: 50%;
}
.custom-checkbox-c input[type=radio] + label::after {
  content: " ";
  top: calc(50% - 9px);
  left: 0;
  width: 18px;
  height: 18px;
  background: #2d2b2c;
  border: 1px solid #2d2b2c;
  border-radius: 50%;
}
.custom-checkbox-c input[type=checkbox]:checked + label::before,
.custom-checkbox-c input[type=radio]:checked + label::before {
  border-color: #2d2b2c;
}
.custom-checkbox-c input[type=checkbox] + label::after,
.custom-checkbox-c input[type=radio] + label::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.custom-checkbox-c input[type=checkbox]:checked + label::after,
.custom-checkbox-c input[type=radio]:checked + label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.custom-checkbox-c label::before,
.custom-checkbox-c label::after {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
.custom-checkbox-c.black label {
  color: #000;
}
.custom-checkbox-c.black label::before {
  border: 1px solid #000;
}
.custom-checkbox-c.black input[type=radio] + label::after {
  background: #000;
  border: 1px solid #000;
}
.custom-checkbox-c.black input[type=checkbox] + label::after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/check-black.svg");
  background-size: 60%;
}
.custom-checkbox-c.black input[type=checkbox]:checked + label::before,
.custom-checkbox-c.black input[type=radio]:checked + label::before {
  border-color: #000;
}

/* Basic styles */
.custom-checkbox-b {
  /* Checkbox */
  /* Radio */
  /* :checked */
  /* Transition */
}
.custom-checkbox-b input[type=checkbox],
.custom-checkbox-b input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.custom-checkbox-b label {
  position: relative;
  display: inline-block;
  padding: 2px 0 0 30px;
  height: 1.5em;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 15px;
  letter-spacing: 0.03em;
  color: #000;
}
.custom-checkbox-b label::before,
.custom-checkbox-b label::after {
  position: absolute;
  top: calc(50% - 9px);
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
}
.custom-checkbox-b label::before {
  border-radius: 50%;
  content: " ";
  border: 1px solid #ef4e69;
}
.custom-checkbox-b input[type=checkbox] + label::after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/check-red.svg");
  background-size: 60%;
  content: " ";
}
.custom-checkbox-b input[type=radio] + label::before {
  border-radius: 50%;
}
.custom-checkbox-b input[type=radio] + label::after {
  content: " ";
  top: calc(50% - 9px);
  left: 0;
  width: 18px;
  height: 18px;
  background: #ef4e69;
  border: 1px solid #ef4e69;
  border-radius: 50%;
}
.custom-checkbox-b input[type=checkbox]:checked + label::before,
.custom-checkbox-b input[type=radio]:checked + label::before {
  border-color: #ef4e69;
}
.custom-checkbox-b input[type=checkbox] + label::after,
.custom-checkbox-b input[type=radio] + label::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.custom-checkbox-b input[type=checkbox]:checked + label::after,
.custom-checkbox-b input[type=radio]:checked + label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.custom-checkbox-b label::before,
.custom-checkbox-b label::after {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
.custom-checkbox-b.black label {
  color: #000;
}
.custom-checkbox-b.black label::before {
  border: 1px solid #000;
}
.custom-checkbox-b.black input[type=radio] + label::after {
  background: #000;
  border: 1px solid #000;
}
.custom-checkbox-b.black input[type=checkbox] + label::after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/check-black.svg");
  background-size: 60%;
}
.custom-checkbox-b.black input[type=checkbox]:checked + label::before,
.custom-checkbox-b.black input[type=radio]:checked + label::before {
  border-color: #000;
}

/* toggle-like-checkbox */
.custom-checkbox-toggle {
  /* :checked */
}
.custom-checkbox-toggle input[type=checkbox],
.custom-checkbox-toggle input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.custom-checkbox-toggle label {
  position: relative;
  display: inline-block;
  padding: 2px 0 0 60px;
  height: 1.5em;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #2d2b2c;
}
.custom-checkbox-toggle label::before,
.custom-checkbox-toggle label::after {
  position: absolute;
  display: block;
}
.custom-checkbox-toggle label::before {
  left: 0;
  top: calc(50% - 11px);
  background: #efeff0;
  width: 48px;
  height: 24px;
  border-radius: 12px;
  content: " ";
}
.custom-checkbox-toggle label::after {
  top: calc(50% - 9px);
  left: 2px;
  background: #979797;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  content: " ";
  transition: 0.3s ease all;
}
.custom-checkbox-toggle input[type=checkbox]:checked + label::after,
.custom-checkbox-toggle input[type=radio]:checked + label::after {
  left: 26px;
  background: #38ba9b;
}

.filter-tag {
  margin: 5px;
  border-radius: 22px;
  font-size: 0.75rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  height: 28px;
  color: #fff;
  padding: 0 16px;
}
@media (max-width: 1023.98px) {
  .filter-tag {
    display: inline-block;
    height: auto;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
.filter-tag.red {
  background: #ef4e69;
}
.filter-tag.blue-light {
  background: #70a3d7;
}
.filter-tag.purple {
  background: #7c51a1;
}
.filter-tag.green {
  background: #38ba9b;
}
.filter-tag.orange {
  background-color: #f79462;
}
.filter-tag.blue {
  background: #003375;
}
.filter-tag.clear {
  color: #ef4e69;
}
.filter-tag.clear .icon {
  width: 12px;
  height: 12px;
  margin-right: 12px;
  font-size: 0;
}
@media (max-width: 1023.98px) {
  .filter-tag.clear .icon {
    position: relative;
    top: -4px;
  }
}
.filter-tag .filter-off {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 12px;
  font-size: 0;
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/x.svg");
}
@media (max-width: 1023.98px) {
  .filter-tag .filter-off {
    position: relative;
    top: -4px;
  }
}

.filter-clear {
  margin-left: auto;
}

.specicon {
  display: block;
  position: absolute;
  top: -55px;
  left: calc(50% - 70px);
  width: 110px;
  height: 110px;
  z-index: 7;
  opacity: 1;
}

.card.card--info,
.card.card--trip {
  padding: 5px;
  display: block;
  cursor: pointer;
}
@media (max-width: 1023.98px) {
  .card.card--info,
  .card.card--trip {
    padding: 3px;
  }
}
.card.card--info .card__inner,
.card.card--trip .card__inner {
  display: flex;
  align-items: flex-end;
  height: 444px;
  position: relative;
  background-color: #000;
  overflow: hidden;
}
@media (max-width: 1279.98px) {
  .card.card--info .card__inner,
  .card.card--trip .card__inner {
    height: calc(50vw - 20px);
  }
}
@media (min-width: 800px) {
  .card.card--info .card__inner:hover .card__content::before,
  .card.card--trip .card__inner:hover .card__content::before {
    top: 0;
  }
  .card.card--info .card__inner:hover .card__text,
  .card.card--trip .card__inner:hover .card__text {
    height: 100%;
    opacity: 1;
  }
}
.card.card--info .card__inner .card__bg,
.card.card--trip .card__inner .card__bg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000 center/cover no-repeat;
  z-index: 1;
}
.card.card--info .card__inner .card__content,
.card.card--trip .card__inner .card__content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  z-index: 2;
}
.card.card--info .card__inner .card__content::before,
.card.card--trip .card__inner .card__content::before {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  height: 100%;
  transition: 0.35s ease;
  z-index: -1;
  content: "";
  background: linear-gradient(0deg, rgba(15, 22, 45, 0.8) 0%, rgba(15, 22, 45, 0.7) 80%, rgba(15, 22, 45, 0) 100%);
}
@media (max-width: 799.98px) {
  .card.card--info .card__inner .card__content::before,
  .card.card--trip .card__inner .card__content::before {
    top: calc(100% - 95px);
  }
}
@media (max-width: 799.98px) {
  .card.card--info .card__inner .card__content::after,
  .card.card--trip .card__inner .card__content::after {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    background: url("../../assets/images/icons/arrow-right-white-lite.webp") center/100% no-repeat;
    content: "";
  }
}
.card.card--info .card__inner .card__locale,
.card.card--trip .card__inner .card__locale {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.8);
}
@media (min-width: 1024px) {
  .card.card--info .card__inner .card__locale,
  .card.card--trip .card__inner .card__locale {
    width: 56px;
    height: 56px;
  }
}
.card.card--info .card__inner .card__locale .icon,
.card.card--trip .card__inner .card__locale .icon {
  width: 21.5px;
  height: 21.5px;
}
@media (min-width: 1024px) {
  .card.card--info .card__inner .card__locale .icon,
  .card.card--trip .card__inner .card__locale .icon {
    width: 30px;
    height: 30px;
  }
}
.card.card--info .card__inner .card__header,
.card.card--trip .card__inner .card__header {
  padding: 15px;
}
.card.card--info .card__inner .card__title,
.card.card--info .card__inner .card__subtitle,
.card.card--info .card__inner .card__text > *,
.card.card--trip .card__inner .card__title,
.card.card--trip .card__inner .card__subtitle,
.card.card--trip .card__inner .card__text > * {
  display: inline-block;
  padding: 5px;
  color: #fff;
}
.card.card--info .card__inner .card__title,
.card.card--trip .card__inner .card__title {
  letter-spacing: 0.01em;
  font-style: normal;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 150%;
}
@media (max-width: 799.98px) {
  .card.card--info .card__inner .card__title,
  .card.card--trip .card__inner .card__title {
    font-size: 1.25rem;
    line-height: 120%;
  }
}
.card.card--info .card__inner .card__subtitle,
.card.card--trip .card__inner .card__subtitle {
  min-height: 18px;
  padding: 3px 10px 3px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 0 10px 0 0;
}
@media (max-width: 1023.98px) {
  .card.card--info .card__inner .card__subtitle,
  .card.card--trip .card__inner .card__subtitle {
    font-size: 0.625rem;
    line-height: 20px;
    position: relative;
    top: 11px;
  }
}
@media (max-width: 599.98px) {
  .card.card--info .card__inner .card__subtitle,
  .card.card--trip .card__inner .card__subtitle {
    display: none;
  }
}
.card.card--info .card__inner .card__text,
.card.card--trip .card__inner .card__text {
  display: flex;
  align-items: center;
  height: 0;
  padding: 0 15px;
  transition: 0.5s ease;
  opacity: 0;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: 0.01em;
}
@media (max-width: 1023.98px) {
  .card.card--info .card__inner .card__text,
  .card.card--trip .card__inner .card__text {
    font-size: 0.8125rem;
  }
}
@media (max-width: 599.98px) {
  .card.card--info .card__inner .card__text,
  .card.card--trip .card__inner .card__text {
    display: none;
  }
}
.card.card--info .card__inner app-destination-icon,
.card.card--trip .card__inner app-destination-icon {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 120px;
  transition: 0.35s ease;
  z-index: 3;
}

.card.card--info .card__inner .card__header {
  padding: 40px 15px 15px;
}
@media (min-width: 1024px) {
  .card.card--info .card__inner .card__header {
    padding-top: 56px;
  }
}
@media (max-width: 799.98px) {
  .card.card--info .card__inner .card__header {
    padding: 40px 35px 15px 15px;
  }
}

.corporate-progress-bar {
  background-color: #4582ec !important;
}

.corporate-button-loader:after,
.corporate-img-loader:before,
.corporate-fullscreen-loader:before {
  border-radius: 50%;
  border-top: 2px solid #4582ec;
  border-right: 2px solid transparent;
  animation: spinner 0.7s linear infinite;
}

.corporate-button-loader:after {
  content: "";
  box-sizing: border-box;
  display: inline-block;
  height: 30px;
  width: 30px;
  margin-left: 10px;
}

.corporate-img-loader:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 60px;
  width: 60px;
  margin-top: calc(-60px / 2);
  margin-left: calc(-60px / 2);
}

.corporate-fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10002;
}
.corporate-fullscreen-loader:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 120px;
  width: 120px;
  margin-top: calc(-120px / 2);
  margin-left: calc(-120px / 2);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/** Light color-scheme **/
/* :root {
  --cc-bg: #fffffff5;
  --cc-text: #222222;
  --cc-border-radius: 0.35em;
  --cc-btn-primary-bg: linear-gradient(to left, #ec1f80 0%, #8a3a95 100%);
  --cc-btn-primary-text: #ffffff;
  --cc-btn-primary-hover-bg: linear-gradient(
    to left,
    #ec1f80 -20%,
    #8a3a95 120%
  );
  --cc-btn-secondary-bg: none;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: none;
  --cc-btn-border-radius: 4px;
  --cc-toggle-bg-off: #b2b2b2;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: linear-gradient(
    to left,
    #ec1f7f8d -20%,
    #8a3a9591 120%
  );
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-toggle-knob-readonly-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #ebebeb;
  --cc-cookie-category-block-bg-hover: #dbdbdb;
  --cc-section-border: #565656;
  --cc-cookie-table-border: #565656;
  --cc-overlay-bg: rgba(4, 6, 8, 0.65);
  --cc-webkit-scrollbar-bg: #7d7d7d;
  --cc-webkit-scrollbar-bg-hover: #7d7d7d;
} */
/** Dark color-scheme **/
/* .c_darkmode {
  --cc-bg: #181b1d;
  --cc-text: #d8e5ea;
  --cc-btn-primary-bg: #a6c4dd;
  --cc-btn-primary-text: #000;
  --cc-btn-primary-hover-bg: #c2dff7;
  --cc-btn-secondary-bg: #33383c;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #3e454a;
  --cc-toggle-bg-off: #667481;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #454c54;
  --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
  --cc-toggle-knob-icon-color: var(--cc-bg);
  --cc-block-text: #b3bfc5;
  --cc-cookie-category-block-bg: #23272a;
  --cc-cookie-category-block-bg-hover: #2b3035;
  --cc-section-border: #292d31;
  --cc-cookie-table-border: #2b3035;
  --cc-webkit-scrollbar-bg: #667481;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
} */
.cc_div *,
.cc_div *:hover,
.cc_div :before,
.cc_div :after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: none;
  font-style: inherit;
  font-variant: normal;
  font-weight: inherit;
  font-family: inherit;
  line-height: 1.2;
  font-size: 1em;
  transition: none;
  animation: none;
  margin: 0;
  padding: 0;
  text-transform: none;
  letter-spacing: unset;
  color: inherit;
  background: none;
  border: none;
  border-radius: unset;
  box-shadow: none;
  text-decoration: none;
  text-align: left;
  visibility: unset;
  height: auto;
  vertical-align: baseline;
}

.cc_div {
  font-size: 16px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: var(--cc-text);
}

.cc_div .c-bn,
.cc_div .b-tl,
#s-ttl,
#c-ttl,
#s-bl td:before {
  font-weight: 600;
}

#cm,
#s-inr,
.cc_div .c-bl,
.cc_div .b-tl,
#s-bl .act .b-acc {
  border-radius: var(--cc-border-radius);
}

#s-bl .act .b-acc {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.cc_div input,
.cc_div button,
.cc_div a {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  overflow: hidden;
}

.cc_div a {
  border-bottom: 1px solid;
}

.cc_div a:hover {
  text-decoration: none;
  border-color: transparent;
}

/* Make elements "animatable" */
.c--anim #cm,
.c--anim #s-cnt,
.c--anim #s-inr,
#cs-ov,
#cm-ov {
  transition: visibility 0.25s linear, opacity 0.25s ease, transform 0.25s ease !important;
}

.c--anim .c-bn {
  transition: background-color 0.25s ease !important;
}

/* start transitions */
.c--anim #cm.bar.slide,
.c--anim .bar.slide #s-inr {
  transition: visibility 0.4s ease, opacity 0.4s ease, transform 0.4s ease !important;
}

.c--anim #cm.bar.slide + #cm-ov,
.c--anim .bar.slide + #cs-ov {
  transition: visibility 0.4s ease, opacity 0.4s ease, transform 0.4s ease !important;
}

#cm.bar.slide,
.cc_div .bar.slide #s-inr {
  transform: translateX(100%);
  opacity: 1;
}

#cm.bar.top.slide,
.cc_div .bar.left.slide #s-inr {
  transform: translateX(-100%);
  opacity: 1;
}

#cm.slide,
.cc_div .slide #s-inr {
  transform: translateY(1.6em);
}

#cm.top.slide {
  transform: translateY(-1.6em);
}

#cm.bar.slide {
  transform: translateY(100%);
}

#cm.bar.top.slide {
  transform: translateY(-100%);
}

/* end transitions */
/* Show modals */
.show--consent .c--anim #cm,
.show--consent .c--anim #cm.bar,
.show--settings .c--anim #s-inr,
.show--settings .c--anim .bar.slide #s-inr {
  opacity: 1;
  transform: scale(1);
  visibility: visible !important;
}

.show--consent .c--anim #cm.box.middle,
.show--consent .c--anim #cm.cloud.middle {
  transform: scale(1) translateY(-50%);
}

.show--settings .c--anim #s-cnt {
  visibility: visible !important;
}

/* Show overlays */
.force--consent.show--consent .c--anim #cm-ov,
.show--settings .c--anim #cs-ov {
  visibility: visible !important;
  opacity: 1 !important;
}

#cm {
  font-family: inherit;
  padding: 1.1em 1.5em 1.4em 1.5em;
  position: fixed;
  z-index: 1;
  background: var(--cc-bg);
  max-width: 24.2em;
  width: 100%;
  bottom: 1.25em;
  right: 1.25em;
  box-shadow: 0 0.625em 1.875em #000000;
  box-shadow: 0 0.625em 1.875em rgba(2, 2, 3, 0.28);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.95);
  line-height: initial;
}

/** fix https://github.com/orestbida/cookieconsent/issues/94 **/
#cc_div #cm {
  display: block !important;
}

#c-ttl {
  margin-bottom: 0.7em;
  font-size: 1.05em;
}

.cloud #c-ttl {
  margin-top: -0.15em;
}

#c-txt {
  font-size: 0.9em;
  line-height: 1.5em;
}

.cc_div #c-bns {
  display: flex;
  justify-content: space-between;
  margin-top: 1.4em;
}

.cc_div .c-bn {
  color: var(--cc-btn-secondary-text);
  background: var(--cc-btn-secondary-bg);
  border: 1px solid var(--cc-btn-secondary-text);
  padding: 1em 1.7em;
  display: inline-block;
  cursor: pointer;
  font-size: 0.82em;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  text-align: center;
  border-radius: var(--cc-btn-border-radius);
  flex: 1;
}

#c-bns button + button,
#s-cnt button + button,
#s-c-bn {
  float: right;
  margin-left: 1em;
}

#s-cnt #s-rall-bn {
  float: none;
}

#cm .c_link:hover,
#cm .c_link:active,
#s-cnt button + button:hover,
#s-cnt button + button:active,
#s-c-bn:active,
#s-c-bn:hover {
  background: var(--cc-btn-secondary-hover-bg);
}

/**
  CookieConsent settings modal
  **/
#s-cnt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  display: table;
  height: 100%;
  visibility: hidden;
}

#s-bl {
  outline: none;
}

#s-bl .title {
  margin-top: 1.4em;
}

#s-bl .title:first-child {
  margin-top: 0;
}

#s-bl .b-bn {
  margin-top: 0;
}

#s-bl .b-acc .p {
  margin-top: 0;
  padding: 1em;
}

#s-cnt .b-bn .b-tl {
  display: block;
  font-family: inherit;
  font-size: 0.95em;
  width: 100%;
  position: relative;
  padding: 1.3em 6.4em 1.3em 2.7em;
  background: none;
  transition: background-color 0.25s ease;
}

#s-cnt .b-bn .b-tl.exp {
  cursor: pointer;
}

#s-cnt .act .b-bn .b-tl {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#s-cnt .b-bn .b-tl:active,
#s-cnt .b-bn .b-tl:hover {
  background: var(--cc-cookie-category-block-bg-hover);
}

#s-bl .b-bn {
  position: relative;
}

#s-bl .c-bl {
  padding: 1em;
  margin-bottom: 0.5em;
  border: 1px solid var(--cc-section-border);
  transition: background-color 0.25s ease;
}

#s-bl .c-bl:hover {
  background: var(--cc-cookie-category-block-bg);
}

#s-bl .c-bl:last-child {
  margin-bottom: 0.5em;
}

#s-bl .c-bl:first-child {
  transition: none;
  padding: 0;
  margin-top: 0;
  border: none;
  margin-bottom: 2em;
}

#s-bl .c-bl:not(.b-ex):first-child:hover {
  background: transparent;
  background: unset;
}

#s-bl .c-bl.b-ex {
  padding: 0;
  border: none;
  background: var(--cc-cookie-category-block-bg);
  transition: none;
}

#s-bl .c-bl.b-ex + .c-bl {
  margin-top: 2em;
}

#s-bl .c-bl.b-ex + .c-bl.b-ex {
  margin-top: 0;
}

#s-bl .c-bl.b-ex:first-child {
  margin-bottom: 1em;
}

#s-bl .c-bl.b-ex:first-child {
  margin-bottom: 0.5em;
}

#s-bl .b-acc {
  max-height: 0;
  overflow: hidden;
  padding-top: 0;
  margin-bottom: 0;
  display: none;
}

#s-bl .act .b-acc {
  max-height: 100%;
  display: block;
  overflow: hidden;
}

#s-cnt .p {
  font-size: 0.9em;
  line-height: 1.5em;
  margin-top: 0.85em;
  color: var(--cc-block-text);
}

.cc_div .b-tg .c-tgl:disabled {
  cursor: not-allowed;
}

#c-vln {
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

#cs {
  padding: 0 1.7em;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
}

#s-inr {
  height: 100%;
  position: relative;
  max-width: 45em;
  margin: 0 auto;
  transform: scale(0.96);
  opacity: 0;
  padding-top: 4.75em;
  padding-bottom: 4.75em;
  position: relative;
  height: 100%;
  overflow: hidden;
  visibility: hidden;
  box-shadow: rgba(3, 6, 9, 0.26) 0px 13px 27px -5px;
}

#s-inr,
#s-hdr,
#s-bns {
  background: var(--cc-bg);
}

#s-bl {
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  height: 100%;
  padding: 1.3em 1.8em;
  display: block;
  width: 100%;
}

#s-bns {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em 1.8em;
  border-top: 1px solid var(--cc-section-border);
  height: 4.75em;
}

.cc_div .cc-link {
  color: var(--cc-btn-primary-bg);
  border-bottom: 1px solid var(--cc-btn-primary-bg);
  display: inline;
  padding-bottom: 0;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

.cc_div .cc-link:hover,
.cc_div .cc-link:active {
  border-color: transparent;
}

#c-bns button:first-child,
#s-bns button:first-child {
  color: var(--cc-btn-primary-text);
  background: var(--cc-btn-primary-bg);
  border: none;
}

#c-bns.swap button:first-child {
  color: var(--cc-btn-secondary-text);
  background: var(--cc-btn-secondary-bg);
}

#c-bns.swap button:last-child {
  color: var(--cc-btn-primary-text);
  background: var(--cc-btn-primary-bg);
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg {
  background: var(--cc-toggle-bg-on);
}

#c-bns button:first-child:active,
#c-bns button:first-child:hover,
#s-bns button:first-child:active,
#s-bns button:first-child:hover,
#c-bns.swap button:last-child:active,
#c-bns.swap button:last-child:hover {
  background: var(--cc-btn-primary-hover-bg);
}

#c-bns.swap button:first-child:active,
#c-bns.swap button:first-child:hover {
  background: var(--cc-btn-secondary-hover-bg);
}

#s-hdr {
  position: absolute;
  top: 0;
  width: 100%;
  display: table;
  padding: 0 1.8em;
  height: 4.75em;
  vertical-align: middle;
  z-index: 2;
  border-bottom: 1px solid var(--cc-section-border);
}

#s-ttl {
  display: table-cell;
  vertical-align: middle;
  font-size: 1em;
}

#s-c-bn {
  padding: 0;
  width: 1.7em;
  height: 1.7em;
  font-size: 1.45em;
  margin: 0;
  border: none;
  font-weight: initial;
  position: relative;
  overflow: hidden;
}

#s-c-bnc {
  display: table-cell;
  vertical-align: middle;
}

.cc_div span.t-lb {
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
}

#c_policy__text {
  height: 31.25em;
  overflow-y: auto;
  margin-top: 1.25em;
}

#c-s-in {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  height: calc(100% - 2.5em);
  max-height: 37.5em;
}

@media screen and (min-width: 688px) {
  /** works only on webkit-based browsers **/
  #s-bl::-webkit-scrollbar {
    width: 0.9em;
    height: 100%;
    background: transparent;
    border-radius: 0 0.25em 0.25em 0;
  }
  #s-bl::-webkit-scrollbar-thumb {
    border: 0.25em solid var(--cc-bg);
    background: var(--cc-webkit-scrollbar-bg);
    border-radius: 100em;
  }
  #s-bl::-webkit-scrollbar-thumb:hover {
    background: var(--cc-webkit-scrollbar-bg-hover);
  }
  #s-bl::-webkit-scrollbar-button {
    width: 10px;
    height: 5px;
  }
}
/** custom checkbox **/
/* The container */
.cc_div .b-tg {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: inline-block;
  margin: auto;
  right: 1.2em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
}

/* Hide the browser's default checkbox */
.cc_div .b-tg .c-tgl {
  position: absolute;
  cursor: pointer;
  display: block;
  top: 0;
  left: 0;
  margin: 0;
  border: 0;
}

/* Create a custom checkbox */
.cc_div .b-tg .c-tg {
  position: absolute;
  background: var(--cc-toggle-bg-off);
  transition: background-color 0.25s ease, box-shadow 0.25s ease;
  pointer-events: none;
}

.cc_div span.t-lb,
.cc_div .b-tg,
.cc_div .b-tg .c-tg,
.cc_div .b-tg .c-tgl {
  width: 3.4em;
  height: 1.5em;
  border-radius: 4em;
}

.cc_div .b-tg .c-tg.c-ro {
  cursor: not-allowed;
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro {
  background: var(--cc-toggle-bg-readonly);
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro .off-i::after,
.cc_div .b-tg .c-tgl ~ .c-tg.c-ro .off-i::before {
  background: var(--cc-toggle-knob-readonly-icon-color);
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro .on-i::before {
  border: solid var(--cc-toggle-knob-readonly-icon-color);
  border-width: 0 2px 2px 0;
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro:after {
  box-shadow: none;
}

/* Style the checkmark/indicator */
.cc_div .b-tg .c-tg:after {
  content: "";
  position: relative;
  display: block;
  left: 0.125em;
  top: 0.125em;
  width: 1.25em;
  height: 1.25em;
  border: none;
  box-sizing: content-box;
  background: var(--cc-toggle-knob-bg);
  box-shadow: 0 1px 2px rgba(24, 32, 35, 0.36);
  transition: transform 0.25s ease;
  border-radius: 100%;
}

/* Show the checkmark when checked */
.cc_div .b-tg .c-tgl:checked ~ .c-tg:after {
  transform: translateX(1.9em);
}

#s-bl table,
#s-bl th,
#s-bl td {
  border: none;
}

#s-bl tbody tr {
  transition: background-color 0.25s ease;
}

#s-bl tbody tr:hover {
  background: var(--cc-cookie-category-block-bg-hover);
}

#s-bl table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

#s-bl td,
#s-bl th {
  padding: 0.8em 0.625em;
  text-align: left;
  vertical-align: top;
  font-size: 0.8em;
  padding-left: 1.2em;
}

#s-bl th {
  font-family: inherit;
  padding: 1.2em 1.2em;
}

#s-bl thead tr:first-child {
  border-bottom: 1px solid var(--cc-cookie-table-border);
}

.force--consent #s-cnt,
.force--consent #cs {
  width: 100vw;
}

#cm-ov,
#cs-ov {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  background: #070707;
  background: var(--cc-overlay-bg);
  display: none;
  transition: none;
}

.show--settings #cs-ov,
.c--anim #cs-ov,
.force--consent .c--anim #cm-ov,
.force--consent.show--consent #cm-ov {
  display: block;
}

#cs-ov {
  z-index: 2;
}

.force--consent .cc_div {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  width: 100vw;
  visibility: hidden;
  transition: visibility 0.25s linear;
}

.force--consent.show--consent .c--anim .cc_div,
.force--consent.show--settings .c--anim .cc_div {
  visibility: visible;
}

.force--consent #cm {
  position: absolute;
}

.force--consent #cm.bar {
  width: 100vw;
  max-width: 100vw;
}

html.force--consent.show--consent,
html.force--consent.show--consent body {
  height: auto !important;
  overflow-x: hidden !important;
}

/** END BLOCK PAGE SCROLL */
/** BEGIN ICONS **/
.cc_div .b-bn .exp::before,
.cc_div .act .b-bn .exp::before {
  border: solid var(--cc-btn-secondary-text);
  border-width: 0 2px 2px 0;
  padding: 0.2em;
  display: inline-block;
  position: absolute;
  content: "";
  margin-right: 15px;
  position: absolute;
  transform: translateY(-50%) rotate(45deg);
  left: 1.2em;
  top: 50%;
}

.cc_div .act .b-bn .b-tl::before {
  transform: translateY(-20%) rotate(225deg);
}

.cc_div .on-i::before {
  border: solid var(--cc-toggle-knob-icon-color);
  border-width: 0 2px 2px 0;
  padding: 0.1em;
  display: inline-block;
  padding-bottom: 0.45em;
  content: "";
  margin: 0 auto;
  transform: rotate(45deg);
  top: 0.37em;
  left: 0.75em;
  position: absolute;
}

#s-c-bn::before,
#s-c-bn::after {
  content: "";
  position: absolute;
  left: 0.82em;
  top: 0.58em;
  height: 0.6em;
  width: 1.5px;
  background: var(--cc-btn-secondary-text);
  transform: rotate(45deg);
  border-radius: 1em;
  margin: 0 auto;
}

#s-c-bn::after {
  transform: rotate(-45deg);
}

.cc_div .off-i,
.cc_div .on-i {
  height: 100%;
  width: 50%;
  position: absolute;
  right: 0;
  display: block;
  text-align: center;
  transition: opacity 0.15s ease;
}

.cc_div .on-i {
  left: 0;
  opacity: 0;
}

.cc_div .off-i::before,
.cc_div .off-i::after {
  right: 0.8em;
  top: 0.42em;
  content: " ";
  height: 0.7em;
  width: 0.09375em;
  display: block;
  background: var(--cc-toggle-knob-icon-color);
  margin: 0 auto;
  position: absolute;
  transform-origin: center;
}

.cc_div .off-i::before {
  transform: rotate(45deg);
}

.cc_div .off-i::after {
  transform: rotate(-45deg);
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg .on-i {
  opacity: 1;
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg .off-i {
  opacity: 0;
}

/** END ICONS **/
#cm.box.middle,
#cm.cloud.middle {
  top: 50%;
  transform: translateY(-37%);
  bottom: auto;
}

#cm.box.middle.zoom,
#cm.cloud.middle.zoom {
  transform: scale(0.95) translateY(-50%);
}

#cm.box.center {
  left: 1em;
  right: 1em;
  margin: 0 auto;
}

/* Start cloud layout */
#cm.cloud {
  max-width: 50em;
  margin: 0 auto;
  text-align: center;
  left: 1em;
  right: 1em;
  overflow: hidden;
  padding: 1.3em 2em;
  width: unset;
}

.cc_div .cloud #c-inr {
  display: table;
  width: 100%;
}

.cc_div .cloud #c-inr-i {
  width: 70%;
  display: table-cell;
  vertical-align: top;
  padding-right: 2.4em;
}

.cc_div .cloud #c-txt {
  font-size: 0.85em;
}

.cc_div .cloud #c-bns {
  min-width: 170px;
  display: table-cell;
  vertical-align: middle;
}

#cm.cloud .c-bn {
  margin: 0.625em 0 0 0;
  width: 100%;
}

#cm.cloud .c-bn:first-child {
  margin: 0;
}

#cm.cloud.left {
  margin-right: 1.25em;
}

#cm.cloud.right {
  margin-left: 1.25em;
}

/* End cloud layout */
/* Start bar layout */
#cm.bar {
  width: 100%;
  max-width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  position: fixed;
  padding: 2em;
}

#cm.bar #c-inr {
  max-width: 32em;
  margin: 0 auto;
}

#cm.bar #c-bns {
  max-width: 33.75em;
}

#cm.bar #cs {
  padding: 0;
}

.cc_div .bar #c-s-in {
  top: 0;
  transform: none;
  height: 100%;
  max-height: 100%;
}

.cc_div .bar #s-hdr,
.cc_div .bar #s-bl,
.cc_div .bar #s-bns {
  padding-left: 1.6em;
  padding-right: 1.6em;
}

.cc_div .bar #cs {
  padding: 0;
}

/* align bar to right by default */
.cc_div .bar #s-inr {
  margin: 0;
  margin-left: auto;
  margin-right: 0;
  border-radius: 0;
  max-width: 32em;
}

.cc_div .bar.left #s-inr {
  margin-left: 0;
  margin-right: auto;
}

/* Force table to not be like tables anymore */
.cc_div .bar #s-bl table,
.cc_div .bar #s-bl thead,
.cc_div .bar #s-bl tbody,
.cc_div .bar #s-bl th,
.cc_div .bar #s-bl td,
.cc_div .bar #s-bl tr,
.cc_div .bar #s-cnt {
  display: block;
}

/* Hide table headers (but not display: none;, for accessibility) */
.cc_div .bar #s-bl thead tr {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.cc_div .bar #s-bl tr {
  border-top: 1px solid var(--cc-cookie-table-border);
}

.cc_div .bar #s-bl td {
  /* Behave  like a "row" */
  border: none;
  position: relative;
  padding-left: 35%;
}

.cc_div .bar #s-bl td:before {
  position: absolute;
  left: 1em;
  padding-right: 0.625em;
  white-space: nowrap;
  content: attr(data-column);
  color: var(--cc-text);
  overflow: hidden;
  text-overflow: ellipsis;
}

/* End bar layout */
/* Positions */
#cm.top {
  bottom: auto;
  top: 1.25em;
}

#cm.left {
  right: auto;
  left: 1.25em;
}

#cm.right {
  left: auto;
  right: 1.25em;
}

#cm.bar.left,
#cm.bar.right {
  left: 0;
  right: 0;
}

#cm.bar.top {
  top: 0;
}

/* end positions */
@media screen and (max-width: 688px) {
  #cm,
  #cm.cloud,
  #cm.left,
  #cm.right {
    width: auto;
    max-width: 100%;
    margin: 0;
    padding: 1.4em !important;
    right: 1em;
    left: 1em;
    bottom: 1em;
    display: block;
  }
  .force--consent #cm,
  .force--consent #cm.cloud {
    width: auto;
    max-width: 100vw;
  }
  #cm.top {
    top: 1em;
    bottom: auto;
  }
  #cm.bottom {
    bottom: 1em;
    top: auto;
  }
  #cm.bar.bottom {
    bottom: 0;
  }
  #cm.cloud .c-bn {
    font-size: 0.85em;
  }
  #s-bns,
  .cc_div .bar #s-bns {
    padding: 1em 1.3em;
  }
  .cc_div .bar #s-inr {
    max-width: 100%;
    width: 100%;
  }
  .cc_div .cloud #c-inr-i {
    padding-right: 0;
  }
  #cs {
    border-radius: 0;
    padding: 0;
  }
  #c-s-in {
    max-height: 100%;
    height: 100%;
    top: 0;
    transform: none;
  }
  .cc_div .b-tg {
    transform: scale(1.1);
    right: 1.1em;
  }
  #s-inr {
    margin: 0;
    padding-bottom: 7.9em;
    border-radius: 0;
  }
  #s-bns {
    height: 7.9em;
  }
  #s-bl,
  .cc_div .bar #s-bl {
    padding: 1.3em;
  }
  #s-hdr,
  .cc_div .bar #s-hdr {
    padding: 0 1.3em;
  }
  /** dynamic table layout **/
  #s-bl table {
    width: 100%;
  }
  #s-inr.bns-t {
    padding-bottom: 10.5em;
  }
  .bns-t #s-bns {
    height: 10.5em;
  }
  .cc_div .bns-t .c-bn {
    font-size: 0.83em;
    padding: 0.9em 1.6em;
  }
  #s-cnt .b-bn .b-tl {
    padding-top: 1.2em;
    padding-bottom: 1.2em;
  }
  /* Force table to not be like tables anymore */
  #s-bl table,
  #s-bl thead,
  #s-bl tbody,
  #s-bl th,
  #s-bl td,
  #s-bl tr,
  #s-cnt {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  #s-bl thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #s-bl tr {
    border-top: 1px solid var(--cc-cookie-table-border);
  }
  #s-bl td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 35%;
  }
  #s-bl td:before {
    position: absolute;
    left: 1em;
    padding-right: 0.625em;
    white-space: nowrap;
    content: attr(data-column);
    color: var(--cc-text);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #cm .c-bn,
  .cc_div .c-bn {
    width: 100%;
    margin-right: 0;
  }
  #s-cnt #s-rall-bn {
    margin-left: 0;
  }
  .cc_div #c-bns {
    flex-direction: column;
  }
  #c-bns button + button,
  #s-cnt button + button {
    margin-top: 0.625em;
    margin-left: 0;
    float: unset;
  }
  #cm.cloud,
  #cm.box {
    left: 1em;
    right: 1em;
    width: auto;
  }
  #cm.cloud.right,
  #cm.cloud.left {
    margin: 0;
  }
  .cc_div .cloud #c-bns,
  .cc_div .cloud #c-inr,
  .cc_div .cloud #c-inr-i {
    display: block;
    width: auto;
    min-width: unset;
  }
  .cc_div .cloud #c-txt {
    font-size: 0.9em;
  }
  .cc_div .cloud #c-bns {
    margin-top: 1.625em;
  }
}
/* Begin IE fixes */
.cc_div.ie #c-vln {
  height: 100%;
  padding-top: 5.62em;
}

.cc_div.ie .bar #c-vln {
  padding-top: 0;
}

.cc_div.ie #cs {
  max-height: 37.5em;
  position: relative;
  top: 0;
  margin-top: -5.625em;
}

.cc_div.ie .bar #cs {
  margin-top: 0;
  max-height: 100%;
}

.cc_div.ie #cm {
  border: 1px solid #dee6e9;
}

.cc_div.ie #c-s-in {
  top: 0;
}

.cc_div.ie .b-tg {
  padding-left: 1em;
  margin-bottom: 0.7em;
}

.cc_div.ie .b-tg .c-tgl:checked ~ .c-tg:after {
  left: 1.95em;
}

.cc_div.ie #s-bl table {
  overflow: auto;
}

.cc_div.ie .b-tg .c-tg {
  display: none;
}

.cc_div.ie .b-tg .c-tgl {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.2em;
  height: auto;
}

.cc_div.ie #s-cnt .b-bn .b-tl {
  padding: 1.3em 6.4em 1.3em 1.4em;
}

.cc_div.ie .bar #s-bl td:before {
  display: none;
}

.cc_div.ie .bar #s-bl td {
  padding: 0.8em 0.625em 0.8em 1.2em;
}

.cc_div.ie .bar #s-bl thead tr {
  position: relative;
}

.cc_div.ie .b-tg .t-lb {
  filter: alpha(opacity=0);
}

.cc_div.ie #cm-ov,
.cc_div.ie #cs-ov {
  filter: alpha(opacity=80);
}

/** END IE FIXES **/
:root {
  --cc-bg: #fffffff5;
  --cc-text: #222222;
  --cc-border-radius: 0.35em;
  --cc-btn-primary-bg: #EF4E69;
  --cc-btn-primary-text: #ffffff;
  --cc-btn-primary-hover-bg: #EF4E69;
  --cc-btn-secondary-bg: #ffffff;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: none;
  --cc-btn-border-radius: 4px;
  --cc-toggle-bg-off: #b2b2b2;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #757575;
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-toggle-knob-readonly-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #ebebeb;
  --cc-cookie-category-block-bg-hover: #dbdbdb;
  --cc-section-border: #565656;
  --cc-cookie-table-border: #565656;
  --cc-overlay-bg: rgba(4, 6, 8, 0.65);
  --cc-webkit-scrollbar-bg: #7d7d7d;
  --cc-webkit-scrollbar-bg-hover: #7d7d7d;
}
:root #c-inr {
  display: flex;
  max-width: 68em !important;
  font-family: Montserrat, sans-serif;
}
:root #c-inr #c-bns {
  flex-direction: column;
  align-items: baseline;
  gap: 10px;
}
:root #c-inr #c-bns #c-s-bn {
  padding: 11px 19px;
}
:root #s-inr #s-bns .c-bn {
  font-size: 13px !important;
  padding: 11px 19px;
}
:root #s-inr #s-bns .c-bn#s-sv-bn {
  background-color: #EF4E69;
}